/* eslint-disable */
// tslint:disable
/**
 * My Cookery Book 2 - backend
 * This is rest api application backend developed on NodeJS and ExpressJS. Writen in typescript.
 *
 * OpenAPI spec version: 0.0.2
 * Contact: tfilosk@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator+.
 * https://github.com/karlvr/openapi-generator-plus
 * Do not edit the class manually.
 */

import "whatwg-fetch";
export const defaultFetch = window.fetch;
import { Configuration } from "./configuration";

export const BASE_PATH = "http://localhost:8080/api".replace(/\/+$/, "");

/**
 *
 * @export
 */
export const COLLECTION_FORMATS = {
	csv: ",",
	ssv: " ",
	tsv: "\t",
	pipes: "|",
};

/**
 *
 * @export
 * @type FetchAPI
 */
export type FetchAPI = typeof defaultFetch;

/**
 *  
 * @export
 * @interface FetchArgs
 */
export interface FetchArgs {
	url: string;
	options: RequestInit;
}

/**
 * 
 * @export
 * @class BaseAPI
 */
export class BaseAPI {
	protected configuration?: Configuration;

	constructor(configuration?: Configuration, protected basePath: string = BASE_PATH, protected fetch: FetchAPI = defaultFetch) {
		if (configuration) {
			this.configuration = configuration;
			this.basePath = configuration.basePath || this.basePath;
		}
	}
};

/**
 * 
 * @export
 * @class RequiredError
 * @extends {Error}
 */
export class RequiredError extends Error {
	constructor(public field: string, msg?: string) {
		super(msg);
		Object.setPrototypeOf(this, RequiredError.prototype);
		this.name = "RequiredError";
	}
}
