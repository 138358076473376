/* eslint-disable */
// tslint:disable
/**
 * My Cookery Book 2 - backend
 * This is rest api application backend developed on NodeJS and ExpressJS. Writen in typescript.
 *
 * OpenAPI spec version: 0.0.2
 * Contact: tfilosk@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator+.
 * https://github.com/karlvr/openapi-generator-plus
 * Do not edit the class manually.
 */

export namespace Api {
	export interface AuthenticatedUser {
		username: string;
		firstName: string | null;
		lastName: string | null;
	}

	export interface Category {
		/**
		 * @type {number}
		 * @memberof Category
		 * minimum: 1
		 */
		id: number;
		name: string;
		createdAt: string;
		updatedAt: string;
	}

	export interface ConfirmEmailRequest {
		username: string;
		/**
		 * @description <p>UUIDv4 from confirmation email</p>
		 * @type {string}
		 * @memberof ConfirmEmailRequest
		 */
		key: string;
	}

	export interface CreateCategory {
		name: string;
	}

	export interface CreateRecipe {
		name: string;
		description: string | null;
		/**
		 * @type {number | null}
		 * @memberof CreateRecipe
		 * minimum: 0
		 * maximum: 100
		 */
		serves: number | null;
		method: string | null;
		sources: string[];
		/**
		 * @type {number}
		 * @memberof CreateRecipe
		 * minimum: 1
		 */
		categoryId: number;
		recipeSections: Api.CreateRecipe.RecipeSection[];
		associatedRecipes: number[];
		tags: number[];
		pictures: Api.CreateRecipe.Picture[];
	}
	
	/**
	 * @export
	 * @namespace CreateRecipe
	 */
	export namespace CreateRecipe {
		export interface RecipeSection {
			name: string | null;
			/**
			 * @type {number}
			 * @memberof RecipeSection
			 * minimum: 1
			 */
			sortNumber: number;
			method: string | null;
			ingredients: Api.CreateRecipe.RecipeSection.Ingredient[];
		}
		
		/**
		 * @export
		 * @namespace RecipeSection
		 */
		export namespace RecipeSection {
			export interface Ingredient {
				name: string;
				/**
				 * @type {number}
				 * @memberof Ingredient
				 * minimum: 1
				 */
				sortNumber: number;
				/**
				 * @type {number | null}
				 * @memberof Ingredient
				 * minimum: 0
				 */
				value: number | null;
				/**
				 * @type {number}
				 * @memberof Ingredient
				 * minimum: 1
				 */
				unitId: number;
			}
		
		}
	
		export interface Picture {
			/**
			 * @type {number}
			 * @memberof Picture
			 * minimum: 1
			 */
			id: number;
			name: string;
			/**
			 * @type {number}
			 * @memberof Picture
			 * minimum: 1
			 */
			sortNumber: number;
		}
	
	}

	export interface CreateRecipe201Response {
		/**
		 * @type {number}
		 * @memberof CreateRecipe201Response
		 * minimum: 1
		 */
		id: number;
	}

	export interface CreateTag {
		name: string;
	}

	export interface CreateUnit {
		name: string;
		abbreviation: string;
		required: boolean;
		/**
		 * @type {number}
		 * @memberof CreateUnit
		 * minimum: 1
		 */
		unitCategoryId: number;
	}

	export interface CreateUnitCategory {
		name: string;
	}

	export interface CreateUser {
		/**
		 * @description <p>Only a-z 0-9 characters allowed</p>
		 * @type {string}
		 * @memberof CreateUser
		 */
		username: string;
		/**
		 * @description <p>New password for user. Should be at least 8 characters long, at least one small letter, one capital letter and one number.</p>
		 * @type {string}
		 * @memberof CreateUser
		 */
		password: string;
		firstName: string | null;
		lastName: string | null;
		email: string;
		notifications: boolean;
		roles: Api.CreateUser.RoleEnum[];
	}
	
	/**
	 * @export
	 * @namespace CreateUser
	 */
	export namespace CreateUser {
		export enum RoleEnum {
			ADMIN = 'ADMIN',
			CREATOR = 'CREATOR'
		}
	
	}

	export interface Error {
		message?: string;
		code: Api.ErrorTypeEnum;
		fields?: { [name: string]: string };
	}

	export enum ErrorTypeEnum {
		GENERALERROR = 'GENERAL_ERROR',
		DATABASEERROR = 'DATABASE_ERROR',
		NOTFOUND = 'NOT_FOUND',
		VALIDATIONFAILED = 'VALIDATION_FAILED',
		INVALIDCREDENTIALS = 'INVALID_CREDENTIALS',
		FORBIDEN = 'FORBIDEN',
		EXPIREDTOKEN = 'EXPIRED_TOKEN',
		INVALIDTOKEN = 'INVALID_TOKEN',
		UNIQUECONSTRAINTERROR = 'UNIQUE_CONSTRAINT_ERROR',
		UNABLETOSENTEMAIL = 'UNABLE_TO_SENT_EMAIL',
		CONSTRAINTFAILED = 'CONSTRAINT_FAILED',
		TOOMANYREQUESTS = 'TOO_MANY_REQUESTS'
	}

	export interface LoginRequest {
		username: string;
		password: string;
	}

	export interface Profile {
		username: string;
		firstName: string | null;
		lastName: string | null;
		email: string;
		confirmed: boolean;
		notifications: boolean;
	}

	export interface Recipe {
		/**
		 * @type {number}
		 * @memberof Recipe
		 * minimum: 1
		 */
		id: number;
		name: string;
		description: string | null;
		/**
		 * @type {number | null}
		 * @memberof Recipe
		 * minimum: 0
		 * maximum: 100
		 */
		serves: number | null;
		method: string | null;
		sources: string[];
		/**
		 * @type {number}
		 * @memberof Recipe
		 * minimum: 1
		 */
		categoryId: number;
		/**
		 * @type {number}
		 * @memberof Recipe
		 * minimum: 1
		 */
		modifierId: number;
		modifier: Api.Recipe.Modifier;
		/**
		 * @type {number}
		 * @memberof Recipe
		 * minimum: 1
		 */
		creatorId: number;
		creator: Api.Recipe.Creator;
		recipeSections: Api.Recipe.RecipeSection[];
		associatedRecipes: Api.Recipe.AssociatedRecipe[];
		tags: Api.Recipe.Tag[];
		pictures: Api.Recipe.Picture[];
		createdAt: string;
		updatedAt: string;
	}
	
	/**
	 * @export
	 * @namespace Recipe
	 */
	export namespace Recipe {
		export interface Modifier {
			username: string;
			firstName: string | null;
			lastName: string | null;
		}
	
		export interface Creator {
			username: string;
			firstName: string | null;
			lastName: string | null;
		}
	
		export interface RecipeSection {
			/**
			 * @type {number}
			 * @memberof RecipeSection
			 * minimum: 1
			 */
			id: number;
			name: string | null;
			/**
			 * @type {number}
			 * @memberof RecipeSection
			 * minimum: 1
			 */
			sortNumber: number;
			method: string | null;
			ingredients: Api.Recipe.RecipeSection.Ingredient[];
		}
		
		/**
		 * @export
		 * @namespace RecipeSection
		 */
		export namespace RecipeSection {
			export interface Ingredient {
				/**
				 * @type {number}
				 * @memberof Ingredient
				 * minimum: 1
				 */
				id: number;
				name: string;
				/**
				 * @type {number}
				 * @memberof Ingredient
				 * minimum: 1
				 */
				sortNumber: number;
				/**
				 * @type {number | null}
				 * @memberof Ingredient
				 * minimum: 0
				 */
				value: number | null;
				/**
				 * @type {number}
				 * @memberof Ingredient
				 * minimum: 1
				 */
				unitId: number;
				unit: Api.Recipe.RecipeSection.Ingredient.Unit;
			}
			
			/**
			 * @export
			 * @namespace Ingredient
			 */
			export namespace Ingredient {
				export interface Unit {
					name: string;
					abbreviation: string;
				}
			
			}
		
		}
	
		export interface AssociatedRecipe {
			/**
			 * @type {number}
			 * @memberof AssociatedRecipe
			 * minimum: 1
			 */
			id: number;
			name: string;
			description: string | null;
		}
	
		export interface Tag {
			/**
			 * @type {number}
			 * @memberof Tag
			 * minimum: 1
			 */
			id: number;
			name: string;
		}
	
		export interface Picture {
			/**
			 * @type {number}
			 * @memberof Picture
			 * minimum: 1
			 */
			id: number;
			name: string;
			/**
			 * @type {number}
			 * @memberof Picture
			 * minimum: 1
			 */
			sortNumber: number;
		}
	
	}

	export interface RecipeSearchCriteria {
		search: string | null;
		/**
		 * @type {number | null}
		 * @memberof RecipeSearchCriteria
		 * minimum: 1
		 */
		categoryId: number | null;
		tags: number[];
		/**
		 * @type {number}
		 * @memberof RecipeSearchCriteria
		 * minimum: 0
		 */
		page: number;
		/**
		 * @type {number}
		 * @memberof RecipeSearchCriteria
		 * minimum: 1
		 */
		pageSize: number;
		orderBy: Api.RecipeSearchCriteria.OrderByEnum;
		order: Api.RecipeSearchCriteria.OrderEnum;
	}
	
	/**
	 * @export
	 * @namespace RecipeSearchCriteria
	 */
	export namespace RecipeSearchCriteria {
		export enum OrderByEnum {
			Name = 'name',
			CreatedAt = 'createdAt',
			UpdatedAt = 'updatedAt'
		}
	
		export enum OrderEnum {
			ASC = 'ASC',
			DESC = 'DESC'
		}
	
	}

	export interface RefreshTokenRequest {
		refreshToken: string;
	}

	export interface ResetPasswordLinkRequest {
		email: string;
	}

	export interface ResetPasswordRequest {
		username: string;
		/**
		 * @description <p>UUIDv4 from confirmation email</p>
		 * @type {string}
		 * @memberof ResetPasswordRequest
		 */
		key: string;
		/**
		 * @description <p>New password for user. Should be at least 8 characters long, at least one small letter, one capital letter and one number.</p>
		 * @type {string}
		 * @memberof ResetPasswordRequest
		 */
		newPassword: string;
	}

	export enum RoleEnum {
		ADMIN = 'ADMIN',
		CREATOR = 'CREATOR'
	}

	export interface SimpleCategory {
		/**
		 * @type {number}
		 * @memberof SimpleCategory
		 * minimum: 1
		 */
		id: number;
		name: string;
	}

	export interface SimplePicture {
		/**
		 * @type {number}
		 * @memberof SimplePicture
		 * minimum: 1
		 */
		id: number;
		name: string;
		/**
		 * @type {number}
		 * @memberof SimplePicture
		 * minimum: 1
		 */
		sortNumber: number;
	}

	export interface SimpleRecipe {
		/**
		 * @type {number}
		 * @memberof SimpleRecipe
		 * minimum: 1
		 */
		id: number;
		name: string;
		description: string | null;
		pictures: Api.SimpleRecipe.Picture[];
		/**
		 * @type {number}
		 * @memberof SimpleRecipe
		 * minimum: 1
		 */
		creatorId: number;
	}
	
	/**
	 * @export
	 * @namespace SimpleRecipe
	 */
	export namespace SimpleRecipe {
		export interface Picture {
			/**
			 * @type {number}
			 * @memberof Picture
			 * minimum: 1
			 */
			id: number;
		}
	
	}

	export interface SimpleRecipePage {
		/**
		 * @type {number}
		 * @memberof SimpleRecipePage
		 * minimum: 0
		 */
		page: number;
		/**
		 * @type {number}
		 * @memberof SimpleRecipePage
		 * minimum: 1
		 */
		pageSize: number;
		rows: Api.SimpleRecipe[];
		/**
		 * @type {number}
		 * @memberof SimpleRecipePage
		 * minimum: 0
		 */
		count: number;
	}

	export interface SimpleTag {
		/**
		 * @type {number}
		 * @memberof SimpleTag
		 * minimum: 1
		 */
		id: number;
		name: string;
	}

	export interface SimpleUnit {
		/**
		 * @type {number}
		 * @memberof SimpleUnit
		 * minimum: 1
		 */
		id: number;
		name: string;
		abbreviation: string;
		required: boolean;
	}

	export interface SimpleUnitCategory {
		/**
		 * @type {number}
		 * @memberof SimpleUnitCategory
		 * minimum: 1
		 */
		id: number;
		name: string;
	}

	export interface SimpleUser {
		/**
		 * @type {number}
		 * @memberof SimpleUser
		 * minimum: 1
		 */
		id: number;
		username: string;
		firstName: string | null;
		lastName: string | null;
		confirmed: boolean;
		notifications: boolean;
		roles: Api.SimpleUser.RoleEnum[];
	}
	
	/**
	 * @export
	 * @namespace SimpleUser
	 */
	export namespace SimpleUser {
		export enum RoleEnum {
			ADMIN = 'ADMIN',
			CREATOR = 'CREATOR'
		}
	
	}

	export enum SortOrderEnum {
		ASC = 'ASC',
		DESC = 'DESC'
	}

	export interface Tag {
		/**
		 * @type {number}
		 * @memberof Tag
		 * minimum: 1
		 */
		id: number;
		name: string;
		createdAt: string;
		updatedAt: string;
	}

	export interface TokenResponse {
		token: string;
		refreshToken: string;
	}

	export interface Unit {
		/**
		 * @type {number}
		 * @memberof Unit
		 * minimum: 1
		 */
		id: number;
		name: string;
		abbreviation: string;
		required: boolean;
		/**
		 * @type {number}
		 * @memberof Unit
		 * minimum: 1
		 */
		unitCategoryId: number;
		createdAt: string;
		updatedAt: string;
	}

	export interface UnitCategory {
		/**
		 * @type {number}
		 * @memberof UnitCategory
		 * minimum: 1
		 */
		id: number;
		name: string;
		createdAt: string;
		updatedAt: string;
	}

	export interface UpdateCategory {
		name: string;
	}

	export interface UpdateProfileRequest {
		/**
		 * @description <p>Old password to verify this user</p>
		 * @type {string}
		 * @memberof UpdateProfileRequest
		 */
		password: string;
		/**
		 * @description <p>New password for user. Should be at least 8 characters long, at least one small letter, one capital letter and one number.</p>
		 * @type {string | null}
		 * @memberof UpdateProfileRequest
		 */
		newPassword: string | null;
		firstName: string | null;
		lastName: string | null;
		email: string;
		notifications: boolean;
	}

	export interface UpdateRecipe {
		name: string;
		description: string | null;
		/**
		 * @type {number | null}
		 * @memberof UpdateRecipe
		 * minimum: 0
		 * maximum: 100
		 */
		serves: number | null;
		method: string | null;
		sources: string[];
		/**
		 * @type {number}
		 * @memberof UpdateRecipe
		 * minimum: 1
		 */
		categoryId: number;
		recipeSections: Api.UpdateRecipe.RecipeSection[];
		associatedRecipes: number[];
		tags: number[];
		pictures: Api.UpdateRecipe.Picture[];
	}
	
	/**
	 * @export
	 * @namespace UpdateRecipe
	 */
	export namespace UpdateRecipe {
		export interface RecipeSection {
			/**
			 * @type {number}
			 * @memberof RecipeSection
			 * minimum: 1
			 */
			id?: number;
			name: string | null;
			/**
			 * @type {number}
			 * @memberof RecipeSection
			 * minimum: 1
			 */
			sortNumber: number;
			method: string | null;
			ingredients: Api.UpdateRecipe.RecipeSection.Ingredient[];
		}
		
		/**
		 * @export
		 * @namespace RecipeSection
		 */
		export namespace RecipeSection {
			export interface Ingredient {
				/**
				 * @type {number}
				 * @memberof Ingredient
				 * minimum: 1
				 */
				id?: number;
				name: string;
				/**
				 * @type {number}
				 * @memberof Ingredient
				 * minimum: 1
				 */
				sortNumber: number;
				/**
				 * @type {number | null}
				 * @memberof Ingredient
				 * minimum: 0
				 */
				value: number | null;
				/**
				 * @type {number}
				 * @memberof Ingredient
				 * minimum: 1
				 */
				unitId: number;
			}
		
		}
	
		export interface Picture {
			/**
			 * @type {number}
			 * @memberof Picture
			 * minimum: 1
			 */
			id: number;
			name: string;
			/**
			 * @type {number}
			 * @memberof Picture
			 * minimum: 1
			 */
			sortNumber: number;
		}
	
	}

	export interface UpdateTag {
		name: string;
	}

	export interface UpdateUnit {
		name: string;
		abbreviation: string;
		required: boolean;
		/**
		 * @type {number}
		 * @memberof UpdateUnit
		 * minimum: 1
		 */
		unitCategoryId: number;
	}

	export interface UpdateUnitCategory {
		name: string;
	}

	export interface UpdateUser {
		/**
		 * @description <p>Only a-z 0-9 characters allowed</p>
		 * @type {string}
		 * @memberof UpdateUser
		 */
		username: string;
		/**
		 * @description <p>If null, password will be not changed. New password for user. Should be at least 8 characters long, at least one small letter, one capital letter and one number.</p>
		 * @type {string | null}
		 * @memberof UpdateUser
		 */
		password: string | null;
		firstName: string | null;
		lastName: string | null;
		email: string;
		notifications: boolean;
		roles: Api.UpdateUser.RoleEnum[];
	}
	
	/**
	 * @export
	 * @namespace UpdateUser
	 */
	export namespace UpdateUser {
		export enum RoleEnum {
			ADMIN = 'ADMIN',
			CREATOR = 'CREATOR'
		}
	
	}

	export interface UploadPicture201Response {
		/**
		 * @type {number}
		 * @memberof UploadPicture201Response
		 * minimum: 1
		 */
		id: number;
	}

	export interface UploadPictureRequest {
		file?: string | Blob;
	}
	
	/**
	 * @export
	 * @namespace UploadPictureRequest
	 */
	export namespace UploadPictureRequest {
		export interface MultipartFormData {
			file?: Api.UploadPictureRequest.MultipartFormData.FilePart;
		}
		
		/**
		 * @export
		 * @namespace MultipartFormData
		 */
		export namespace MultipartFormData {
			export interface FilePart {
				value: string | Blob;
				filename?: string;
			}
		
		}
	
	}

	export interface User {
		/**
		 * @type {number}
		 * @memberof User
		 * minimum: 1
		 */
		id: number;
		username: string;
		firstName: string | null;
		lastName: string | null;
		email: string;
		confirmed: boolean;
		notifications: boolean;
		roles: Api.User.RoleEnum[];
		createdAt: string;
		updatedAt: string;
	}
	
	/**
	 * @export
	 * @namespace User
	 */
	export namespace User {
		export enum RoleEnum {
			ADMIN = 'ADMIN',
			CREATOR = 'CREATOR'
		}
	
	}

}
