/* eslint-disable */
// tslint:disable
/**
 * My Cookery Book 2 - backend
 * This is rest api application backend developed on NodeJS and ExpressJS. Writen in typescript.
 *
 * OpenAPI spec version: 0.0.2
 * Contact: tfilosk@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator+.
 * https://github.com/karlvr/openapi-generator-plus
 * Do not edit the class manually.
 */

import { Configuration } from "./configuration";
import { BASE_PATH, COLLECTION_FORMATS, FetchAPI, FetchArgs, BaseAPI, RequiredError, defaultFetch } from "./runtime";
import { Api } from "./models";

export type FactoryFunction<T> = (configuration?: Configuration, basePath?: string, fetch?: FetchAPI) => T;

/**
 * AuthApi - fetch parameter creator
 * @export
 */
export const AuthApiFetchParamCreator = function (configuration?: Configuration) {
	return {
		/**
		 * @param {Api.ConfirmEmailRequest} request
		 * @param {RequestInit} [options] Override http request option.
		 * @throws {RequiredError}
		 */
		confirmEmail(request: Api.ConfirmEmailRequest, options: RequestInit = {}): FetchArgs {
			// verify required parameter 'request' is not null or undefined
			if (request === null || request === undefined) {
				throw new RequiredError('request', 'Required parameter request was null or undefined when calling confirmEmail.');
			}
			let localVarPath = `/auth/confirm`;
			const localVarPathQueryStart = localVarPath.indexOf("?");
			const localVarRequestOptions: RequestInit = Object.assign({ method: 'PATCH' }, options);
			const localVarHeaderParameter: Headers = options.headers ? new Headers(options.headers) : new Headers();
			const localVarQueryParameter = new URLSearchParams(localVarPathQueryStart !== -1 ? localVarPath.substring(localVarPathQueryStart + 1) : "");
			if (localVarPathQueryStart !== -1) {
				localVarPath = localVarPath.substring(0, localVarPathQueryStart);
			}

			localVarHeaderParameter.set('Content-Type', 'application/json');

			localVarRequestOptions.headers = localVarHeaderParameter;
	
			if (request !== undefined) {
				localVarRequestOptions.body = JSON.stringify(request || {});
			}

			const localVarQueryParameterString = localVarQueryParameter.toString();
			if (localVarQueryParameterString) {
				localVarPath += "?" + localVarQueryParameterString;
			}
			return {
				url: localVarPath,
				options: localVarRequestOptions,
			};
		},
		/**
		 * @param {Api.LoginRequest} request
		 * @param {RequestInit} [options] Override http request option.
		 * @throws {RequiredError}
		 */
		login(request: Api.LoginRequest, options: RequestInit = {}): FetchArgs {
			// verify required parameter 'request' is not null or undefined
			if (request === null || request === undefined) {
				throw new RequiredError('request', 'Required parameter request was null or undefined when calling login.');
			}
			let localVarPath = `/auth/login`;
			const localVarPathQueryStart = localVarPath.indexOf("?");
			const localVarRequestOptions: RequestInit = Object.assign({ method: 'POST' }, options);
			const localVarHeaderParameter: Headers = options.headers ? new Headers(options.headers) : new Headers();
			const localVarQueryParameter = new URLSearchParams(localVarPathQueryStart !== -1 ? localVarPath.substring(localVarPathQueryStart + 1) : "");
			if (localVarPathQueryStart !== -1) {
				localVarPath = localVarPath.substring(0, localVarPathQueryStart);
			}

			localVarHeaderParameter.set('Content-Type', 'application/json');

			localVarRequestOptions.headers = localVarHeaderParameter;
	
			if (request !== undefined) {
				localVarRequestOptions.body = JSON.stringify(request || {});
			}

			const localVarQueryParameterString = localVarQueryParameter.toString();
			if (localVarQueryParameterString) {
				localVarPath += "?" + localVarQueryParameterString;
			}
			return {
				url: localVarPath,
				options: localVarRequestOptions,
			};
		},
		/**
		 * @param {Api.RefreshTokenRequest} request
		 * @param {RequestInit} [options] Override http request option.
		 * @throws {RequiredError}
		 */
		refreshToken(request: Api.RefreshTokenRequest, options: RequestInit = {}): FetchArgs {
			// verify required parameter 'request' is not null or undefined
			if (request === null || request === undefined) {
				throw new RequiredError('request', 'Required parameter request was null or undefined when calling refreshToken.');
			}
			let localVarPath = `/auth/refresh`;
			const localVarPathQueryStart = localVarPath.indexOf("?");
			const localVarRequestOptions: RequestInit = Object.assign({ method: 'POST' }, options);
			const localVarHeaderParameter: Headers = options.headers ? new Headers(options.headers) : new Headers();
			const localVarQueryParameter = new URLSearchParams(localVarPathQueryStart !== -1 ? localVarPath.substring(localVarPathQueryStart + 1) : "");
			if (localVarPathQueryStart !== -1) {
				localVarPath = localVarPath.substring(0, localVarPathQueryStart);
			}

			localVarHeaderParameter.set('Content-Type', 'application/json');

			localVarRequestOptions.headers = localVarHeaderParameter;
	
			if (request !== undefined) {
				localVarRequestOptions.body = JSON.stringify(request || {});
			}

			const localVarQueryParameterString = localVarQueryParameter.toString();
			if (localVarQueryParameterString) {
				localVarPath += "?" + localVarQueryParameterString;
			}
			return {
				url: localVarPath,
				options: localVarRequestOptions,
			};
		},
		/**
		 * @param {Api.ResetPasswordRequest} request
		 * @param {RequestInit} [options] Override http request option.
		 * @throws {RequiredError}
		 */
		resetPassword(request: Api.ResetPasswordRequest, options: RequestInit = {}): FetchArgs {
			// verify required parameter 'request' is not null or undefined
			if (request === null || request === undefined) {
				throw new RequiredError('request', 'Required parameter request was null or undefined when calling resetPassword.');
			}
			let localVarPath = `/auth/reset`;
			const localVarPathQueryStart = localVarPath.indexOf("?");
			const localVarRequestOptions: RequestInit = Object.assign({ method: 'PATCH' }, options);
			const localVarHeaderParameter: Headers = options.headers ? new Headers(options.headers) : new Headers();
			const localVarQueryParameter = new URLSearchParams(localVarPathQueryStart !== -1 ? localVarPath.substring(localVarPathQueryStart + 1) : "");
			if (localVarPathQueryStart !== -1) {
				localVarPath = localVarPath.substring(0, localVarPathQueryStart);
			}

			localVarHeaderParameter.set('Content-Type', 'application/json');

			localVarRequestOptions.headers = localVarHeaderParameter;
	
			if (request !== undefined) {
				localVarRequestOptions.body = JSON.stringify(request || {});
			}

			const localVarQueryParameterString = localVarQueryParameter.toString();
			if (localVarQueryParameterString) {
				localVarPath += "?" + localVarQueryParameterString;
			}
			return {
				url: localVarPath,
				options: localVarRequestOptions,
			};
		},
		/**
		 * @param {Api.ResetPasswordLinkRequest} request
		 * @param {RequestInit} [options] Override http request option.
		 * @throws {RequiredError}
		 */
		resetPasswordLink(request: Api.ResetPasswordLinkRequest, options: RequestInit = {}): FetchArgs {
			// verify required parameter 'request' is not null or undefined
			if (request === null || request === undefined) {
				throw new RequiredError('request', 'Required parameter request was null or undefined when calling resetPasswordLink.');
			}
			let localVarPath = `/auth/reset`;
			const localVarPathQueryStart = localVarPath.indexOf("?");
			const localVarRequestOptions: RequestInit = Object.assign({ method: 'POST' }, options);
			const localVarHeaderParameter: Headers = options.headers ? new Headers(options.headers) : new Headers();
			const localVarQueryParameter = new URLSearchParams(localVarPathQueryStart !== -1 ? localVarPath.substring(localVarPathQueryStart + 1) : "");
			if (localVarPathQueryStart !== -1) {
				localVarPath = localVarPath.substring(0, localVarPathQueryStart);
			}

			localVarHeaderParameter.set('Content-Type', 'application/json');

			localVarRequestOptions.headers = localVarHeaderParameter;
	
			if (request !== undefined) {
				localVarRequestOptions.body = JSON.stringify(request || {});
			}

			const localVarQueryParameterString = localVarQueryParameter.toString();
			if (localVarQueryParameterString) {
				localVarPath += "?" + localVarQueryParameterString;
			}
			return {
				url: localVarPath,
				options: localVarRequestOptions,
			};
		},
		/**
		 * @param {RequestInit} [options] Override http request option.
		 * @throws {RequiredError}
		 */
		user(options: RequestInit = {}): FetchArgs {
			let localVarPath = `/auth/user`;
			const localVarPathQueryStart = localVarPath.indexOf("?");
			const localVarRequestOptions: RequestInit = Object.assign({ method: 'GET' }, options);
			const localVarHeaderParameter: Headers = options.headers ? new Headers(options.headers) : new Headers();
			const localVarQueryParameter = new URLSearchParams(localVarPathQueryStart !== -1 ? localVarPath.substring(localVarPathQueryStart + 1) : "");
			if (localVarPathQueryStart !== -1) {
				localVarPath = localVarPath.substring(0, localVarPathQueryStart);
			}

			// authentication bearerAuth required
			// http authorization required
			if (configuration && configuration.authorization) {
				const localVarAuthorizationValue = typeof configuration.authorization === 'function'
					? configuration.authorization('bearerAuth')
					: configuration.authorization;
				if (localVarAuthorizationValue !== null) {
					localVarHeaderParameter.set("Authorization", "Bearer " + localVarAuthorizationValue);
				}
			}
			localVarRequestOptions.headers = localVarHeaderParameter;

			const localVarQueryParameterString = localVarQueryParameter.toString();
			if (localVarQueryParameterString) {
				localVarPath += "?" + localVarQueryParameterString;
			}
			return {
				url: localVarPath,
				options: localVarRequestOptions,
			};
		},
	}
};

/**
 * AuthApi - functional programming interface
 * @export
 */
export const AuthApiFp = function(configuration?: Configuration) {
	return {
		/**
		 * @param {Api.ConfirmEmailRequest} request
		 * @param {RequestInit} [options] Override http request option.
		 * @throws {RequiredError}
		 */
		confirmEmail(request: Api.ConfirmEmailRequest, options?: RequestInit): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
			const localVarFetchArgs = AuthApiFetchParamCreator(configuration).confirmEmail(request, options);
			return (fetch: FetchAPI = defaultFetch, basePath: string = BASE_PATH) => {
				return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
					const contentType = response.headers.get('Content-Type');
					const mimeType = contentType ? contentType.replace(/;.*/, '') : undefined;
					
					if (response.status === 204) {
						return response;
					}
					if (response.status === 401) {
						if (mimeType === 'application/json') {
							throw response;
						}
						throw response;
					}
					if (response.status === 422) {
						if (mimeType === 'application/json') {
							throw response;
						}
						throw response;
					}
					if (response.status === 429) {
						if (mimeType === 'application/json') {
							throw response;
						}
						throw response;
					}
					if (response.status === 500) {
						if (mimeType === 'application/json') {
							throw response;
						}
						throw response;
					}
					throw response;
				});
			};
		},
		/**
		 * @param {Api.LoginRequest} request
		 * @param {RequestInit} [options] Override http request option.
		 * @throws {RequiredError}
		 */
		login(request: Api.LoginRequest, options?: RequestInit): (fetch?: FetchAPI, basePath?: string) => Promise<Api.TokenResponse> {
			const localVarFetchArgs = AuthApiFetchParamCreator(configuration).login(request, options);
			return (fetch: FetchAPI = defaultFetch, basePath: string = BASE_PATH) => {
				return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
					const contentType = response.headers.get('Content-Type');
					const mimeType = contentType ? contentType.replace(/;.*/, '') : undefined;
					
					if (response.status === 200) {
						if (mimeType === 'application/json') {
							return response.json() as any;
						}
						throw response;
					}
					if (response.status === 401) {
						if (mimeType === 'application/json') {
							throw response;
						}
						throw response;
					}
					if (response.status === 422) {
						if (mimeType === 'application/json') {
							throw response;
						}
						throw response;
					}
					if (response.status === 429) {
						if (mimeType === 'application/json') {
							throw response;
						}
						throw response;
					}
					if (response.status === 500) {
						if (mimeType === 'application/json') {
							throw response;
						}
						throw response;
					}
					throw response;
				});
			};
		},
		/**
		 * @param {Api.RefreshTokenRequest} request
		 * @param {RequestInit} [options] Override http request option.
		 * @throws {RequiredError}
		 */
		refreshToken(request: Api.RefreshTokenRequest, options?: RequestInit): (fetch?: FetchAPI, basePath?: string) => Promise<Api.TokenResponse> {
			const localVarFetchArgs = AuthApiFetchParamCreator(configuration).refreshToken(request, options);
			return (fetch: FetchAPI = defaultFetch, basePath: string = BASE_PATH) => {
				return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
					const contentType = response.headers.get('Content-Type');
					const mimeType = contentType ? contentType.replace(/;.*/, '') : undefined;
					
					if (response.status === 200) {
						if (mimeType === 'application/json') {
							return response.json() as any;
						}
						throw response;
					}
					if (response.status === 401) {
						if (mimeType === 'application/json') {
							throw response;
						}
						throw response;
					}
					if (response.status === 429) {
						if (mimeType === 'application/json') {
							throw response;
						}
						throw response;
					}
					if (response.status === 500) {
						if (mimeType === 'application/json') {
							throw response;
						}
						throw response;
					}
					throw response;
				});
			};
		},
		/**
		 * @param {Api.ResetPasswordRequest} request
		 * @param {RequestInit} [options] Override http request option.
		 * @throws {RequiredError}
		 */
		resetPassword(request: Api.ResetPasswordRequest, options?: RequestInit): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
			const localVarFetchArgs = AuthApiFetchParamCreator(configuration).resetPassword(request, options);
			return (fetch: FetchAPI = defaultFetch, basePath: string = BASE_PATH) => {
				return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
					const contentType = response.headers.get('Content-Type');
					const mimeType = contentType ? contentType.replace(/;.*/, '') : undefined;
					
					if (response.status === 204) {
						return response;
					}
					if (response.status === 401) {
						if (mimeType === 'application/json') {
							throw response;
						}
						throw response;
					}
					if (response.status === 404) {
						if (mimeType === 'application/json') {
							throw response;
						}
						throw response;
					}
					if (response.status === 422) {
						if (mimeType === 'application/json') {
							throw response;
						}
						throw response;
					}
					if (response.status === 429) {
						if (mimeType === 'application/json') {
							throw response;
						}
						throw response;
					}
					if (response.status === 500) {
						if (mimeType === 'application/json') {
							throw response;
						}
						throw response;
					}
					throw response;
				});
			};
		},
		/**
		 * @param {Api.ResetPasswordLinkRequest} request
		 * @param {RequestInit} [options] Override http request option.
		 * @throws {RequiredError}
		 */
		resetPasswordLink(request: Api.ResetPasswordLinkRequest, options?: RequestInit): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
			const localVarFetchArgs = AuthApiFetchParamCreator(configuration).resetPasswordLink(request, options);
			return (fetch: FetchAPI = defaultFetch, basePath: string = BASE_PATH) => {
				return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
					const contentType = response.headers.get('Content-Type');
					const mimeType = contentType ? contentType.replace(/;.*/, '') : undefined;
					
					if (response.status === 204) {
						return response;
					}
					if (response.status === 404) {
						if (mimeType === 'application/json') {
							throw response;
						}
						throw response;
					}
					if (response.status === 422) {
						if (mimeType === 'application/json') {
							throw response;
						}
						throw response;
					}
					if (response.status === 429) {
						if (mimeType === 'application/json') {
							throw response;
						}
						throw response;
					}
					if (response.status === 500) {
						if (mimeType === 'application/json') {
							throw response;
						}
						throw response;
					}
					throw response;
				});
			};
		},
		/**
		 * @param {RequestInit} [options] Override http request option.
		 * @throws {RequiredError}
		 */
		user(options?: RequestInit): (fetch?: FetchAPI, basePath?: string) => Promise<Api.AuthenticatedUser> {
			const localVarFetchArgs = AuthApiFetchParamCreator(configuration).user(options);
			return (fetch: FetchAPI = defaultFetch, basePath: string = BASE_PATH) => {
				return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
					const contentType = response.headers.get('Content-Type');
					const mimeType = contentType ? contentType.replace(/;.*/, '') : undefined;
					
					if (response.status === 200) {
						if (mimeType === 'application/json') {
							return response.json() as any;
						}
						throw response;
					}
					if (response.status === 401) {
						if (mimeType === 'application/json') {
							throw response;
						}
						throw response;
					}
					if (response.status === 404) {
						if (mimeType === 'application/json') {
							throw response;
						}
						throw response;
					}
					if (response.status === 500) {
						if (mimeType === 'application/json') {
							throw response;
						}
						throw response;
					}
					throw response;
				});
			};
		},
	}
};

/**
 * AuthApi - factory interface
 * @export
 */
export const AuthApiFactory: FactoryFunction<AuthApi> = function (configuration?: Configuration, basePath?: string, fetch?: FetchAPI) {
	return new AuthApi(configuration, basePath, fetch);
};

/**
 * AuthApi - object-oriented interface
 * @export
 * @class AuthApi
 * @extends {BaseAPI}
 */
export class AuthApi extends BaseAPI {
	/**
	 * @param {Api.ConfirmEmailRequest} request
	 * @param {RequestInit} [options] Override http request option.
	 * @throws {RequiredError}
	 */
	public confirmEmail(request: Api.ConfirmEmailRequest, options?: RequestInit) {
		return AuthApiFp(this.configuration).confirmEmail(request, options)(this.fetch, this.basePath);
	}

	/**
	 * @param {Api.LoginRequest} request
	 * @param {RequestInit} [options] Override http request option.
	 * @throws {RequiredError}
	 */
	public login(request: Api.LoginRequest, options?: RequestInit) {
		return AuthApiFp(this.configuration).login(request, options)(this.fetch, this.basePath);
	}

	/**
	 * @param {Api.RefreshTokenRequest} request
	 * @param {RequestInit} [options] Override http request option.
	 * @throws {RequiredError}
	 */
	public refreshToken(request: Api.RefreshTokenRequest, options?: RequestInit) {
		return AuthApiFp(this.configuration).refreshToken(request, options)(this.fetch, this.basePath);
	}

	/**
	 * @param {Api.ResetPasswordRequest} request
	 * @param {RequestInit} [options] Override http request option.
	 * @throws {RequiredError}
	 */
	public resetPassword(request: Api.ResetPasswordRequest, options?: RequestInit) {
		return AuthApiFp(this.configuration).resetPassword(request, options)(this.fetch, this.basePath);
	}

	/**
	 * @param {Api.ResetPasswordLinkRequest} request
	 * @param {RequestInit} [options] Override http request option.
	 * @throws {RequiredError}
	 */
	public resetPasswordLink(request: Api.ResetPasswordLinkRequest, options?: RequestInit) {
		return AuthApiFp(this.configuration).resetPasswordLink(request, options)(this.fetch, this.basePath);
	}

	/**
	 * @param {RequestInit} [options] Override http request option.
	 * @throws {RequiredError}
	 */
	public user(options?: RequestInit) {
		return AuthApiFp(this.configuration).user(options)(this.fetch, this.basePath);
	}

}
/**
 * CategoryApi - fetch parameter creator
 * @export
 */
export const CategoryApiFetchParamCreator = function (configuration?: Configuration) {
	return {
		/**
		 * @param {Api.CreateCategory} request
		 * @param {RequestInit} [options] Override http request option.
		 * @throws {RequiredError}
		 */
		createCategory(request: Api.CreateCategory, options: RequestInit = {}): FetchArgs {
			// verify required parameter 'request' is not null or undefined
			if (request === null || request === undefined) {
				throw new RequiredError('request', 'Required parameter request was null or undefined when calling createCategory.');
			}
			let localVarPath = `/category`;
			const localVarPathQueryStart = localVarPath.indexOf("?");
			const localVarRequestOptions: RequestInit = Object.assign({ method: 'POST' }, options);
			const localVarHeaderParameter: Headers = options.headers ? new Headers(options.headers) : new Headers();
			const localVarQueryParameter = new URLSearchParams(localVarPathQueryStart !== -1 ? localVarPath.substring(localVarPathQueryStart + 1) : "");
			if (localVarPathQueryStart !== -1) {
				localVarPath = localVarPath.substring(0, localVarPathQueryStart);
			}

			// authentication bearerAuth required
			// http authorization required
			if (configuration && configuration.authorization) {
				const localVarAuthorizationValue = typeof configuration.authorization === 'function'
					? configuration.authorization('bearerAuth')
					: configuration.authorization;
				if (localVarAuthorizationValue !== null) {
					localVarHeaderParameter.set("Authorization", "Bearer " + localVarAuthorizationValue);
				}
			}
			localVarHeaderParameter.set('Content-Type', 'application/json');

			localVarRequestOptions.headers = localVarHeaderParameter;
	
			if (request !== undefined) {
				localVarRequestOptions.body = JSON.stringify(request || {});
			}

			const localVarQueryParameterString = localVarQueryParameter.toString();
			if (localVarQueryParameterString) {
				localVarPath += "?" + localVarQueryParameterString;
			}
			return {
				url: localVarPath,
				options: localVarRequestOptions,
			};
		},
		/**
		 * @param {number} categoryId
		 * @param {RequestInit} [options] Override http request option.
		 * @throws {RequiredError}
		 */
		deleteCategory(categoryId: number, options: RequestInit = {}): FetchArgs {
			// verify required parameter 'categoryId' is not null or undefined
			if (categoryId === null || categoryId === undefined) {
				throw new RequiredError('categoryId', 'Required parameter categoryId was null or undefined when calling deleteCategory.');
			}
			let localVarPath = `/category/{categoryId}`
				.replace('{categoryId}', encodeURIComponent(String(categoryId)));
			const localVarPathQueryStart = localVarPath.indexOf("?");
			const localVarRequestOptions: RequestInit = Object.assign({ method: 'DELETE' }, options);
			const localVarHeaderParameter: Headers = options.headers ? new Headers(options.headers) : new Headers();
			const localVarQueryParameter = new URLSearchParams(localVarPathQueryStart !== -1 ? localVarPath.substring(localVarPathQueryStart + 1) : "");
			if (localVarPathQueryStart !== -1) {
				localVarPath = localVarPath.substring(0, localVarPathQueryStart);
			}

			// authentication bearerAuth required
			// http authorization required
			if (configuration && configuration.authorization) {
				const localVarAuthorizationValue = typeof configuration.authorization === 'function'
					? configuration.authorization('bearerAuth')
					: configuration.authorization;
				if (localVarAuthorizationValue !== null) {
					localVarHeaderParameter.set("Authorization", "Bearer " + localVarAuthorizationValue);
				}
			}
			localVarRequestOptions.headers = localVarHeaderParameter;

			const localVarQueryParameterString = localVarQueryParameter.toString();
			if (localVarQueryParameterString) {
				localVarPath += "?" + localVarQueryParameterString;
			}
			return {
				url: localVarPath,
				options: localVarRequestOptions,
			};
		},
		/**
		 * @param {RequestInit} [options] Override http request option.
		 * @throws {RequiredError}
		 */
		getCategories(options: RequestInit = {}): FetchArgs {
			let localVarPath = `/category`;
			const localVarPathQueryStart = localVarPath.indexOf("?");
			const localVarRequestOptions: RequestInit = Object.assign({ method: 'GET' }, options);
			const localVarHeaderParameter: Headers = options.headers ? new Headers(options.headers) : new Headers();
			const localVarQueryParameter = new URLSearchParams(localVarPathQueryStart !== -1 ? localVarPath.substring(localVarPathQueryStart + 1) : "");
			if (localVarPathQueryStart !== -1) {
				localVarPath = localVarPath.substring(0, localVarPathQueryStart);
			}

			// authentication bearerAuth required
			// http authorization required
			if (configuration && configuration.authorization) {
				const localVarAuthorizationValue = typeof configuration.authorization === 'function'
					? configuration.authorization('bearerAuth')
					: configuration.authorization;
				if (localVarAuthorizationValue !== null) {
					localVarHeaderParameter.set("Authorization", "Bearer " + localVarAuthorizationValue);
				}
			}
			localVarRequestOptions.headers = localVarHeaderParameter;

			const localVarQueryParameterString = localVarQueryParameter.toString();
			if (localVarQueryParameterString) {
				localVarPath += "?" + localVarQueryParameterString;
			}
			return {
				url: localVarPath,
				options: localVarRequestOptions,
			};
		},
		/**
		 * @param {number} categoryId
		 * @param {RequestInit} [options] Override http request option.
		 * @throws {RequiredError}
		 */
		getCategory(categoryId: number, options: RequestInit = {}): FetchArgs {
			// verify required parameter 'categoryId' is not null or undefined
			if (categoryId === null || categoryId === undefined) {
				throw new RequiredError('categoryId', 'Required parameter categoryId was null or undefined when calling getCategory.');
			}
			let localVarPath = `/category/{categoryId}`
				.replace('{categoryId}', encodeURIComponent(String(categoryId)));
			const localVarPathQueryStart = localVarPath.indexOf("?");
			const localVarRequestOptions: RequestInit = Object.assign({ method: 'GET' }, options);
			const localVarHeaderParameter: Headers = options.headers ? new Headers(options.headers) : new Headers();
			const localVarQueryParameter = new URLSearchParams(localVarPathQueryStart !== -1 ? localVarPath.substring(localVarPathQueryStart + 1) : "");
			if (localVarPathQueryStart !== -1) {
				localVarPath = localVarPath.substring(0, localVarPathQueryStart);
			}

			// authentication bearerAuth required
			// http authorization required
			if (configuration && configuration.authorization) {
				const localVarAuthorizationValue = typeof configuration.authorization === 'function'
					? configuration.authorization('bearerAuth')
					: configuration.authorization;
				if (localVarAuthorizationValue !== null) {
					localVarHeaderParameter.set("Authorization", "Bearer " + localVarAuthorizationValue);
				}
			}
			localVarRequestOptions.headers = localVarHeaderParameter;

			const localVarQueryParameterString = localVarQueryParameter.toString();
			if (localVarQueryParameterString) {
				localVarPath += "?" + localVarQueryParameterString;
			}
			return {
				url: localVarPath,
				options: localVarRequestOptions,
			};
		},
		/**
		 * @param {number} categoryId
		 * @param {Api.UpdateCategory} request
		 * @param {RequestInit} [options] Override http request option.
		 * @throws {RequiredError}
		 */
		updateCategory(categoryId: number, request: Api.UpdateCategory, options: RequestInit = {}): FetchArgs {
			// verify required parameter 'categoryId' is not null or undefined
			if (categoryId === null || categoryId === undefined) {
				throw new RequiredError('categoryId', 'Required parameter categoryId was null or undefined when calling updateCategory.');
			}
			// verify required parameter 'request' is not null or undefined
			if (request === null || request === undefined) {
				throw new RequiredError('request', 'Required parameter request was null or undefined when calling updateCategory.');
			}
			let localVarPath = `/category/{categoryId}`
				.replace('{categoryId}', encodeURIComponent(String(categoryId)));
			const localVarPathQueryStart = localVarPath.indexOf("?");
			const localVarRequestOptions: RequestInit = Object.assign({ method: 'PUT' }, options);
			const localVarHeaderParameter: Headers = options.headers ? new Headers(options.headers) : new Headers();
			const localVarQueryParameter = new URLSearchParams(localVarPathQueryStart !== -1 ? localVarPath.substring(localVarPathQueryStart + 1) : "");
			if (localVarPathQueryStart !== -1) {
				localVarPath = localVarPath.substring(0, localVarPathQueryStart);
			}

			// authentication bearerAuth required
			// http authorization required
			if (configuration && configuration.authorization) {
				const localVarAuthorizationValue = typeof configuration.authorization === 'function'
					? configuration.authorization('bearerAuth')
					: configuration.authorization;
				if (localVarAuthorizationValue !== null) {
					localVarHeaderParameter.set("Authorization", "Bearer " + localVarAuthorizationValue);
				}
			}
			localVarHeaderParameter.set('Content-Type', 'application/json');

			localVarRequestOptions.headers = localVarHeaderParameter;
	
			if (request !== undefined) {
				localVarRequestOptions.body = JSON.stringify(request || {});
			}

			const localVarQueryParameterString = localVarQueryParameter.toString();
			if (localVarQueryParameterString) {
				localVarPath += "?" + localVarQueryParameterString;
			}
			return {
				url: localVarPath,
				options: localVarRequestOptions,
			};
		},
	}
};

/**
 * CategoryApi - functional programming interface
 * @export
 */
export const CategoryApiFp = function(configuration?: Configuration) {
	return {
		/**
		 * @param {Api.CreateCategory} request
		 * @param {RequestInit} [options] Override http request option.
		 * @throws {RequiredError}
		 */
		createCategory(request: Api.CreateCategory, options?: RequestInit): (fetch?: FetchAPI, basePath?: string) => Promise<Api.Category> {
			const localVarFetchArgs = CategoryApiFetchParamCreator(configuration).createCategory(request, options);
			return (fetch: FetchAPI = defaultFetch, basePath: string = BASE_PATH) => {
				return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
					const contentType = response.headers.get('Content-Type');
					const mimeType = contentType ? contentType.replace(/;.*/, '') : undefined;
					
					if (response.status === 201) {
						if (mimeType === 'application/json') {
							return response.json() as any;
						}
						throw response;
					}
					if (response.status === 401) {
						if (mimeType === 'application/json') {
							throw response;
						}
						throw response;
					}
					if (response.status === 409) {
						if (mimeType === 'application/json') {
							throw response;
						}
						throw response;
					}
					if (response.status === 422) {
						if (mimeType === 'application/json') {
							throw response;
						}
						throw response;
					}
					if (response.status === 500) {
						if (mimeType === 'application/json') {
							throw response;
						}
						throw response;
					}
					throw response;
				});
			};
		},
		/**
		 * @param {number} categoryId
		 * @param {RequestInit} [options] Override http request option.
		 * @throws {RequiredError}
		 */
		deleteCategory(categoryId: number, options?: RequestInit): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
			const localVarFetchArgs = CategoryApiFetchParamCreator(configuration).deleteCategory(categoryId, options);
			return (fetch: FetchAPI = defaultFetch, basePath: string = BASE_PATH) => {
				return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
					const contentType = response.headers.get('Content-Type');
					const mimeType = contentType ? contentType.replace(/;.*/, '') : undefined;
					
					if (response.status === 204) {
						return response;
					}
					if (response.status === 401) {
						if (mimeType === 'application/json') {
							throw response;
						}
						throw response;
					}
					if (response.status === 404) {
						if (mimeType === 'application/json') {
							throw response;
						}
						throw response;
					}
					if (response.status === 422) {
						if (mimeType === 'application/json') {
							throw response;
						}
						throw response;
					}
					if (response.status === 500) {
						if (mimeType === 'application/json') {
							throw response;
						}
						throw response;
					}
					throw response;
				});
			};
		},
		/**
		 * @param {RequestInit} [options] Override http request option.
		 * @throws {RequiredError}
		 */
		getCategories(options?: RequestInit): (fetch?: FetchAPI, basePath?: string) => Promise<Api.SimpleCategory[]> {
			const localVarFetchArgs = CategoryApiFetchParamCreator(configuration).getCategories(options);
			return (fetch: FetchAPI = defaultFetch, basePath: string = BASE_PATH) => {
				return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
					const contentType = response.headers.get('Content-Type');
					const mimeType = contentType ? contentType.replace(/;.*/, '') : undefined;
					
					if (response.status === 200) {
						if (mimeType === 'application/json') {
							return response.json() as any;
						}
						throw response;
					}
					if (response.status === 401) {
						if (mimeType === 'application/json') {
							throw response;
						}
						throw response;
					}
					if (response.status === 500) {
						if (mimeType === 'application/json') {
							throw response;
						}
						throw response;
					}
					throw response;
				});
			};
		},
		/**
		 * @param {number} categoryId
		 * @param {RequestInit} [options] Override http request option.
		 * @throws {RequiredError}
		 */
		getCategory(categoryId: number, options?: RequestInit): (fetch?: FetchAPI, basePath?: string) => Promise<Api.Category> {
			const localVarFetchArgs = CategoryApiFetchParamCreator(configuration).getCategory(categoryId, options);
			return (fetch: FetchAPI = defaultFetch, basePath: string = BASE_PATH) => {
				return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
					const contentType = response.headers.get('Content-Type');
					const mimeType = contentType ? contentType.replace(/;.*/, '') : undefined;
					
					if (response.status === 200) {
						if (mimeType === 'application/json') {
							return response.json() as any;
						}
						throw response;
					}
					if (response.status === 401) {
						if (mimeType === 'application/json') {
							throw response;
						}
						throw response;
					}
					if (response.status === 404) {
						if (mimeType === 'application/json') {
							throw response;
						}
						throw response;
					}
					if (response.status === 422) {
						if (mimeType === 'application/json') {
							throw response;
						}
						throw response;
					}
					if (response.status === 500) {
						if (mimeType === 'application/json') {
							throw response;
						}
						throw response;
					}
					throw response;
				});
			};
		},
		/**
		 * @param {number} categoryId
		 * @param {Api.UpdateCategory} request
		 * @param {RequestInit} [options] Override http request option.
		 * @throws {RequiredError}
		 */
		updateCategory(categoryId: number, request: Api.UpdateCategory, options?: RequestInit): (fetch?: FetchAPI, basePath?: string) => Promise<Api.Category> {
			const localVarFetchArgs = CategoryApiFetchParamCreator(configuration).updateCategory(categoryId, request, options);
			return (fetch: FetchAPI = defaultFetch, basePath: string = BASE_PATH) => {
				return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
					const contentType = response.headers.get('Content-Type');
					const mimeType = contentType ? contentType.replace(/;.*/, '') : undefined;
					
					if (response.status === 200) {
						if (mimeType === 'application/json') {
							return response.json() as any;
						}
						throw response;
					}
					if (response.status === 401) {
						if (mimeType === 'application/json') {
							throw response;
						}
						throw response;
					}
					if (response.status === 404) {
						if (mimeType === 'application/json') {
							throw response;
						}
						throw response;
					}
					if (response.status === 409) {
						if (mimeType === 'application/json') {
							throw response;
						}
						throw response;
					}
					if (response.status === 422) {
						if (mimeType === 'application/json') {
							throw response;
						}
						throw response;
					}
					if (response.status === 500) {
						if (mimeType === 'application/json') {
							throw response;
						}
						throw response;
					}
					throw response;
				});
			};
		},
	}
};

/**
 * CategoryApi - factory interface
 * @export
 */
export const CategoryApiFactory: FactoryFunction<CategoryApi> = function (configuration?: Configuration, basePath?: string, fetch?: FetchAPI) {
	return new CategoryApi(configuration, basePath, fetch);
};

/**
 * CategoryApi - object-oriented interface
 * @export
 * @class CategoryApi
 * @extends {BaseAPI}
 */
export class CategoryApi extends BaseAPI {
	/**
	 * @param {Api.CreateCategory} request
	 * @param {RequestInit} [options] Override http request option.
	 * @throws {RequiredError}
	 */
	public createCategory(request: Api.CreateCategory, options?: RequestInit) {
		return CategoryApiFp(this.configuration).createCategory(request, options)(this.fetch, this.basePath);
	}

	/**
	 * @param {number} categoryId
	 * @param {RequestInit} [options] Override http request option.
	 * @throws {RequiredError}
	 */
	public deleteCategory(categoryId: number, options?: RequestInit) {
		return CategoryApiFp(this.configuration).deleteCategory(categoryId, options)(this.fetch, this.basePath);
	}

	/**
	 * @param {RequestInit} [options] Override http request option.
	 * @throws {RequiredError}
	 */
	public getCategories(options?: RequestInit) {
		return CategoryApiFp(this.configuration).getCategories(options)(this.fetch, this.basePath);
	}

	/**
	 * @param {number} categoryId
	 * @param {RequestInit} [options] Override http request option.
	 * @throws {RequiredError}
	 */
	public getCategory(categoryId: number, options?: RequestInit) {
		return CategoryApiFp(this.configuration).getCategory(categoryId, options)(this.fetch, this.basePath);
	}

	/**
	 * @param {number} categoryId
	 * @param {Api.UpdateCategory} request
	 * @param {RequestInit} [options] Override http request option.
	 * @throws {RequiredError}
	 */
	public updateCategory(categoryId: number, request: Api.UpdateCategory, options?: RequestInit) {
		return CategoryApiFp(this.configuration).updateCategory(categoryId, request, options)(this.fetch, this.basePath);
	}

}
/**
 * HealthApi - fetch parameter creator
 * @export
 */
export const HealthApiFetchParamCreator = function (configuration?: Configuration) {
	return {
		/**
		 * @param {RequestInit} [options] Override http request option.
		 * @throws {RequiredError}
		 */
		health(options: RequestInit = {}): FetchArgs {
			let localVarPath = `/health`;
			const localVarPathQueryStart = localVarPath.indexOf("?");
			const localVarRequestOptions: RequestInit = Object.assign({ method: 'GET' }, options);
			const localVarHeaderParameter: Headers = options.headers ? new Headers(options.headers) : new Headers();
			const localVarQueryParameter = new URLSearchParams(localVarPathQueryStart !== -1 ? localVarPath.substring(localVarPathQueryStart + 1) : "");
			if (localVarPathQueryStart !== -1) {
				localVarPath = localVarPath.substring(0, localVarPathQueryStart);
			}

			localVarRequestOptions.headers = localVarHeaderParameter;

			const localVarQueryParameterString = localVarQueryParameter.toString();
			if (localVarQueryParameterString) {
				localVarPath += "?" + localVarQueryParameterString;
			}
			return {
				url: localVarPath,
				options: localVarRequestOptions,
			};
		},
	}
};

/**
 * HealthApi - functional programming interface
 * @export
 */
export const HealthApiFp = function(configuration?: Configuration) {
	return {
		/**
		 * @param {RequestInit} [options] Override http request option.
		 * @throws {RequiredError}
		 */
		health(options?: RequestInit): (fetch?: FetchAPI, basePath?: string) => Promise<string> {
			const localVarFetchArgs = HealthApiFetchParamCreator(configuration).health(options);
			return (fetch: FetchAPI = defaultFetch, basePath: string = BASE_PATH) => {
				return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
					const contentType = response.headers.get('Content-Type');
					const mimeType = contentType ? contentType.replace(/;.*/, '') : undefined;
					
					if (response.status === 200) {
						if (mimeType === 'text/plain') {
							return response.text();
						}
						throw response;
					}
					throw response;
				});
			};
		},
	}
};

/**
 * HealthApi - factory interface
 * @export
 */
export const HealthApiFactory: FactoryFunction<HealthApi> = function (configuration?: Configuration, basePath?: string, fetch?: FetchAPI) {
	return new HealthApi(configuration, basePath, fetch);
};

/**
 * HealthApi - object-oriented interface
 * @export
 * @class HealthApi
 * @extends {BaseAPI}
 */
export class HealthApi extends BaseAPI {
	/**
	 * @param {RequestInit} [options] Override http request option.
	 * @throws {RequiredError}
	 */
	public health(options?: RequestInit) {
		return HealthApiFp(this.configuration).health(options)(this.fetch, this.basePath);
	}

}
/**
 * PictureApi - fetch parameter creator
 * @export
 */
export const PictureApiFetchParamCreator = function (configuration?: Configuration) {
	return {
		/**
		 * @param {number} pictureId
		 * @param {RequestInit} [options] Override http request option.
		 * @throws {RequiredError}
		 */
		getPictureData(pictureId: number, options: RequestInit = {}): FetchArgs {
			// verify required parameter 'pictureId' is not null or undefined
			if (pictureId === null || pictureId === undefined) {
				throw new RequiredError('pictureId', 'Required parameter pictureId was null or undefined when calling getPictureData.');
			}
			let localVarPath = `/picture/data/{pictureId}`
				.replace('{pictureId}', encodeURIComponent(String(pictureId)));
			const localVarPathQueryStart = localVarPath.indexOf("?");
			const localVarRequestOptions: RequestInit = Object.assign({ method: 'GET' }, options);
			const localVarHeaderParameter: Headers = options.headers ? new Headers(options.headers) : new Headers();
			const localVarQueryParameter = new URLSearchParams(localVarPathQueryStart !== -1 ? localVarPath.substring(localVarPathQueryStart + 1) : "");
			if (localVarPathQueryStart !== -1) {
				localVarPath = localVarPath.substring(0, localVarPathQueryStart);
			}

			// authentication bearerAuth required
			// http authorization required
			if (configuration && configuration.authorization) {
				const localVarAuthorizationValue = typeof configuration.authorization === 'function'
					? configuration.authorization('bearerAuth')
					: configuration.authorization;
				if (localVarAuthorizationValue !== null) {
					localVarHeaderParameter.set("Authorization", "Bearer " + localVarAuthorizationValue);
				}
			}
			localVarRequestOptions.headers = localVarHeaderParameter;

			const localVarQueryParameterString = localVarQueryParameter.toString();
			if (localVarQueryParameterString) {
				localVarPath += "?" + localVarQueryParameterString;
			}
			return {
				url: localVarPath,
				options: localVarRequestOptions,
			};
		},
		/**
		 * @param {number} recipeId
		 * @param {RequestInit} [options] Override http request option.
		 * @throws {RequiredError}
		 */
		getPicturesByRecipe(recipeId: number, options: RequestInit = {}): FetchArgs {
			// verify required parameter 'recipeId' is not null or undefined
			if (recipeId === null || recipeId === undefined) {
				throw new RequiredError('recipeId', 'Required parameter recipeId was null or undefined when calling getPicturesByRecipe.');
			}
			let localVarPath = `/picture/byRecipe/{recipeId}`
				.replace('{recipeId}', encodeURIComponent(String(recipeId)));
			const localVarPathQueryStart = localVarPath.indexOf("?");
			const localVarRequestOptions: RequestInit = Object.assign({ method: 'GET' }, options);
			const localVarHeaderParameter: Headers = options.headers ? new Headers(options.headers) : new Headers();
			const localVarQueryParameter = new URLSearchParams(localVarPathQueryStart !== -1 ? localVarPath.substring(localVarPathQueryStart + 1) : "");
			if (localVarPathQueryStart !== -1) {
				localVarPath = localVarPath.substring(0, localVarPathQueryStart);
			}

			// authentication bearerAuth required
			// http authorization required
			if (configuration && configuration.authorization) {
				const localVarAuthorizationValue = typeof configuration.authorization === 'function'
					? configuration.authorization('bearerAuth')
					: configuration.authorization;
				if (localVarAuthorizationValue !== null) {
					localVarHeaderParameter.set("Authorization", "Bearer " + localVarAuthorizationValue);
				}
			}
			localVarRequestOptions.headers = localVarHeaderParameter;

			const localVarQueryParameterString = localVarQueryParameter.toString();
			if (localVarQueryParameterString) {
				localVarPath += "?" + localVarQueryParameterString;
			}
			return {
				url: localVarPath,
				options: localVarRequestOptions,
			};
		},
		/**
		 * @param {number} pictureId
		 * @param {RequestInit} [options] Override http request option.
		 * @throws {RequiredError}
		 */
		getPictureThumbnail(pictureId: number, options: RequestInit = {}): FetchArgs {
			// verify required parameter 'pictureId' is not null or undefined
			if (pictureId === null || pictureId === undefined) {
				throw new RequiredError('pictureId', 'Required parameter pictureId was null or undefined when calling getPictureThumbnail.');
			}
			let localVarPath = `/picture/thumbnail/{pictureId}`
				.replace('{pictureId}', encodeURIComponent(String(pictureId)));
			const localVarPathQueryStart = localVarPath.indexOf("?");
			const localVarRequestOptions: RequestInit = Object.assign({ method: 'GET' }, options);
			const localVarHeaderParameter: Headers = options.headers ? new Headers(options.headers) : new Headers();
			const localVarQueryParameter = new URLSearchParams(localVarPathQueryStart !== -1 ? localVarPath.substring(localVarPathQueryStart + 1) : "");
			if (localVarPathQueryStart !== -1) {
				localVarPath = localVarPath.substring(0, localVarPathQueryStart);
			}

			// authentication bearerAuth required
			// http authorization required
			if (configuration && configuration.authorization) {
				const localVarAuthorizationValue = typeof configuration.authorization === 'function'
					? configuration.authorization('bearerAuth')
					: configuration.authorization;
				if (localVarAuthorizationValue !== null) {
					localVarHeaderParameter.set("Authorization", "Bearer " + localVarAuthorizationValue);
				}
			}
			localVarRequestOptions.headers = localVarHeaderParameter;

			const localVarQueryParameterString = localVarQueryParameter.toString();
			if (localVarQueryParameterString) {
				localVarPath += "?" + localVarQueryParameterString;
			}
			return {
				url: localVarPath,
				options: localVarRequestOptions,
			};
		},
		/**
		 * @param {Api.UploadPictureRequest.MultipartFormData} [request]
		 * @param {RequestInit} [options] Override http request option.
		 * @throws {RequiredError}
		 */
		uploadPicture(request: Api.UploadPictureRequest.MultipartFormData | undefined, options: RequestInit = {}): FetchArgs {
			let localVarPath = `/picture/upload`;
			const localVarPathQueryStart = localVarPath.indexOf("?");
			const localVarRequestOptions: RequestInit = Object.assign({ method: 'POST' }, options);
			const localVarHeaderParameter: Headers = options.headers ? new Headers(options.headers) : new Headers();
			const localVarQueryParameter = new URLSearchParams(localVarPathQueryStart !== -1 ? localVarPath.substring(localVarPathQueryStart + 1) : "");
			if (localVarPathQueryStart !== -1) {
				localVarPath = localVarPath.substring(0, localVarPathQueryStart);
			}

			// authentication bearerAuth required
			// http authorization required
			if (configuration && configuration.authorization) {
				const localVarAuthorizationValue = typeof configuration.authorization === 'function'
					? configuration.authorization('bearerAuth')
					: configuration.authorization;
				if (localVarAuthorizationValue !== null) {
					localVarHeaderParameter.set("Authorization", "Bearer " + localVarAuthorizationValue);
				}
			}
			//removed content type

			localVarRequestOptions.headers = localVarHeaderParameter;
	
			if (request !== undefined) {
				const localVarFormData = new FormData();
				if (request.file !== undefined) {
					localVarFormData.append('file', request.file.value, request.file.filename);
				}
				localVarRequestOptions.body = localVarFormData;
			}

			const localVarQueryParameterString = localVarQueryParameter.toString();
			if (localVarQueryParameterString) {
				localVarPath += "?" + localVarQueryParameterString;
			}
			return {
				url: localVarPath,
				options: localVarRequestOptions,
			};
		},
	}
};

/**
 * PictureApi - functional programming interface
 * @export
 */
export const PictureApiFp = function(configuration?: Configuration) {
	return {
		/**
		 * @param {number} pictureId
		 * @param {RequestInit} [options] Override http request option.
		 * @throws {RequiredError}
		 */
		getPictureData(pictureId: number, options?: RequestInit): (fetch?: FetchAPI, basePath?: string) => Promise<string | Blob> {
			const localVarFetchArgs = PictureApiFetchParamCreator(configuration).getPictureData(pictureId, options);
			return (fetch: FetchAPI = defaultFetch, basePath: string = BASE_PATH) => {
				return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
					const contentType = response.headers.get('Content-Type');
					const mimeType = contentType ? contentType.replace(/;.*/, '') : undefined;
					
					if (response.status === 200) {
						if (mimeType === 'image/jpeg') {
							return response.blob();
						}
						throw response;
					}
					if (response.status === 401) {
						if (mimeType === 'application/json') {
							throw response;
						}
						throw response;
					}
					if (response.status === 404) {
						if (mimeType === 'application/json') {
							throw response;
						}
						throw response;
					}
					if (response.status === 422) {
						if (mimeType === 'application/json') {
							throw response;
						}
						throw response;
					}
					if (response.status === 500) {
						if (mimeType === 'application/json') {
							throw response;
						}
						throw response;
					}
					throw response;
				});
			};
		},
		/**
		 * @param {number} recipeId
		 * @param {RequestInit} [options] Override http request option.
		 * @throws {RequiredError}
		 */
		getPicturesByRecipe(recipeId: number, options?: RequestInit): (fetch?: FetchAPI, basePath?: string) => Promise<Api.SimplePicture[]> {
			const localVarFetchArgs = PictureApiFetchParamCreator(configuration).getPicturesByRecipe(recipeId, options);
			return (fetch: FetchAPI = defaultFetch, basePath: string = BASE_PATH) => {
				return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
					const contentType = response.headers.get('Content-Type');
					const mimeType = contentType ? contentType.replace(/;.*/, '') : undefined;
					
					if (response.status === 200) {
						if (mimeType === 'application/json') {
							return response.json() as any;
						}
						throw response;
					}
					if (response.status === 401) {
						if (mimeType === 'application/json') {
							throw response;
						}
						throw response;
					}
					if (response.status === 422) {
						if (mimeType === 'application/json') {
							throw response;
						}
						throw response;
					}
					if (response.status === 500) {
						if (mimeType === 'application/json') {
							throw response;
						}
						throw response;
					}
					throw response;
				});
			};
		},
		/**
		 * @param {number} pictureId
		 * @param {RequestInit} [options] Override http request option.
		 * @throws {RequiredError}
		 */
		getPictureThumbnail(pictureId: number, options?: RequestInit): (fetch?: FetchAPI, basePath?: string) => Promise<string | Blob> {
			const localVarFetchArgs = PictureApiFetchParamCreator(configuration).getPictureThumbnail(pictureId, options);
			return (fetch: FetchAPI = defaultFetch, basePath: string = BASE_PATH) => {
				return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
					const contentType = response.headers.get('Content-Type');
					const mimeType = contentType ? contentType.replace(/;.*/, '') : undefined;
					
					if (response.status === 200) {
						if (mimeType === 'image/jpeg') {
							return response.blob();
						}
						throw response;
					}
					if (response.status === 401) {
						if (mimeType === 'application/json') {
							throw response;
						}
						throw response;
					}
					if (response.status === 404) {
						if (mimeType === 'application/json') {
							throw response;
						}
						throw response;
					}
					if (response.status === 422) {
						if (mimeType === 'application/json') {
							throw response;
						}
						throw response;
					}
					if (response.status === 500) {
						if (mimeType === 'application/json') {
							throw response;
						}
						throw response;
					}
					throw response;
				});
			};
		},
		/**
		 * @param {Api.UploadPictureRequest.MultipartFormData} [request]
		 * @param {RequestInit} [options] Override http request option.
		 * @throws {RequiredError}
		 */
		uploadPicture(request: Api.UploadPictureRequest.MultipartFormData | undefined, options?: RequestInit): (fetch?: FetchAPI, basePath?: string) => Promise<Api.UploadPicture201Response> {
			const localVarFetchArgs = PictureApiFetchParamCreator(configuration).uploadPicture(request, options);
			return (fetch: FetchAPI = defaultFetch, basePath: string = BASE_PATH) => {
				return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
					const contentType = response.headers.get('Content-Type');
					const mimeType = contentType ? contentType.replace(/;.*/, '') : undefined;
					
					if (response.status === 201) {
						if (mimeType === 'application/json') {
							return response.json() as any;
						}
						throw response;
					}
					if (response.status === 401) {
						if (mimeType === 'application/json') {
							throw response;
						}
						throw response;
					}
					if (response.status === 422) {
						if (mimeType === 'application/json') {
							throw response;
						}
						throw response;
					}
					if (response.status === 500) {
						if (mimeType === 'application/json') {
							throw response;
						}
						throw response;
					}
					throw response;
				});
			};
		},
	}
};

/**
 * PictureApi - factory interface
 * @export
 */
export const PictureApiFactory: FactoryFunction<PictureApi> = function (configuration?: Configuration, basePath?: string, fetch?: FetchAPI) {
	return new PictureApi(configuration, basePath, fetch);
};

/**
 * PictureApi - object-oriented interface
 * @export
 * @class PictureApi
 * @extends {BaseAPI}
 */
export class PictureApi extends BaseAPI {
	/**
	 * @param {number} pictureId
	 * @param {RequestInit} [options] Override http request option.
	 * @throws {RequiredError}
	 */
	public getPictureData(pictureId: number, options?: RequestInit) {
		return PictureApiFp(this.configuration).getPictureData(pictureId, options)(this.fetch, this.basePath);
	}

	/**
	 * @param {number} recipeId
	 * @param {RequestInit} [options] Override http request option.
	 * @throws {RequiredError}
	 */
	public getPicturesByRecipe(recipeId: number, options?: RequestInit) {
		return PictureApiFp(this.configuration).getPicturesByRecipe(recipeId, options)(this.fetch, this.basePath);
	}

	/**
	 * @param {number} pictureId
	 * @param {RequestInit} [options] Override http request option.
	 * @throws {RequiredError}
	 */
	public getPictureThumbnail(pictureId: number, options?: RequestInit) {
		return PictureApiFp(this.configuration).getPictureThumbnail(pictureId, options)(this.fetch, this.basePath);
	}

	/**
	 * @param {Api.UploadPictureRequest.MultipartFormData} [request]
	 * @param {RequestInit} [options] Override http request option.
	 * @throws {RequiredError}
	 */
	public uploadPicture(request: Api.UploadPictureRequest.MultipartFormData | undefined, options?: RequestInit) {
		return PictureApiFp(this.configuration).uploadPicture(request, options)(this.fetch, this.basePath);
	}

}
/**
 * RecipeApi - fetch parameter creator
 * @export
 */
export const RecipeApiFetchParamCreator = function (configuration?: Configuration) {
	return {
		/**
		 * @param {Api.CreateRecipe} request
		 * @param {RequestInit} [options] Override http request option.
		 * @throws {RequiredError}
		 */
		createRecipe(request: Api.CreateRecipe, options: RequestInit = {}): FetchArgs {
			// verify required parameter 'request' is not null or undefined
			if (request === null || request === undefined) {
				throw new RequiredError('request', 'Required parameter request was null or undefined when calling createRecipe.');
			}
			let localVarPath = `/recipe`;
			const localVarPathQueryStart = localVarPath.indexOf("?");
			const localVarRequestOptions: RequestInit = Object.assign({ method: 'POST' }, options);
			const localVarHeaderParameter: Headers = options.headers ? new Headers(options.headers) : new Headers();
			const localVarQueryParameter = new URLSearchParams(localVarPathQueryStart !== -1 ? localVarPath.substring(localVarPathQueryStart + 1) : "");
			if (localVarPathQueryStart !== -1) {
				localVarPath = localVarPath.substring(0, localVarPathQueryStart);
			}

			// authentication bearerAuth required
			// http authorization required
			if (configuration && configuration.authorization) {
				const localVarAuthorizationValue = typeof configuration.authorization === 'function'
					? configuration.authorization('bearerAuth')
					: configuration.authorization;
				if (localVarAuthorizationValue !== null) {
					localVarHeaderParameter.set("Authorization", "Bearer " + localVarAuthorizationValue);
				}
			}
			localVarHeaderParameter.set('Content-Type', 'application/json');

			localVarRequestOptions.headers = localVarHeaderParameter;
	
			if (request !== undefined) {
				localVarRequestOptions.body = JSON.stringify(request || {});
			}

			const localVarQueryParameterString = localVarQueryParameter.toString();
			if (localVarQueryParameterString) {
				localVarPath += "?" + localVarQueryParameterString;
			}
			return {
				url: localVarPath,
				options: localVarRequestOptions,
			};
		},
		/**
		 * @param {number} recipeId
		 * @param {RequestInit} [options] Override http request option.
		 * @throws {RequiredError}
		 */
		deleteRecipe(recipeId: number, options: RequestInit = {}): FetchArgs {
			// verify required parameter 'recipeId' is not null or undefined
			if (recipeId === null || recipeId === undefined) {
				throw new RequiredError('recipeId', 'Required parameter recipeId was null or undefined when calling deleteRecipe.');
			}
			let localVarPath = `/recipe/{recipeId}`
				.replace('{recipeId}', encodeURIComponent(String(recipeId)));
			const localVarPathQueryStart = localVarPath.indexOf("?");
			const localVarRequestOptions: RequestInit = Object.assign({ method: 'DELETE' }, options);
			const localVarHeaderParameter: Headers = options.headers ? new Headers(options.headers) : new Headers();
			const localVarQueryParameter = new URLSearchParams(localVarPathQueryStart !== -1 ? localVarPath.substring(localVarPathQueryStart + 1) : "");
			if (localVarPathQueryStart !== -1) {
				localVarPath = localVarPath.substring(0, localVarPathQueryStart);
			}

			// authentication bearerAuth required
			// http authorization required
			if (configuration && configuration.authorization) {
				const localVarAuthorizationValue = typeof configuration.authorization === 'function'
					? configuration.authorization('bearerAuth')
					: configuration.authorization;
				if (localVarAuthorizationValue !== null) {
					localVarHeaderParameter.set("Authorization", "Bearer " + localVarAuthorizationValue);
				}
			}
			localVarRequestOptions.headers = localVarHeaderParameter;

			const localVarQueryParameterString = localVarQueryParameter.toString();
			if (localVarQueryParameterString) {
				localVarPath += "?" + localVarQueryParameterString;
			}
			return {
				url: localVarPath,
				options: localVarRequestOptions,
			};
		},
		/**
		 * @param {Api.RecipeSearchCriteria} request
		 * @param {RequestInit} [options] Override http request option.
		 * @throws {RequiredError}
		 */
		findRecipe(request: Api.RecipeSearchCriteria, options: RequestInit = {}): FetchArgs {
			// verify required parameter 'request' is not null or undefined
			if (request === null || request === undefined) {
				throw new RequiredError('request', 'Required parameter request was null or undefined when calling findRecipe.');
			}
			let localVarPath = `/recipe/find`;
			const localVarPathQueryStart = localVarPath.indexOf("?");
			const localVarRequestOptions: RequestInit = Object.assign({ method: 'POST' }, options);
			const localVarHeaderParameter: Headers = options.headers ? new Headers(options.headers) : new Headers();
			const localVarQueryParameter = new URLSearchParams(localVarPathQueryStart !== -1 ? localVarPath.substring(localVarPathQueryStart + 1) : "");
			if (localVarPathQueryStart !== -1) {
				localVarPath = localVarPath.substring(0, localVarPathQueryStart);
			}

			// authentication bearerAuth required
			// http authorization required
			if (configuration && configuration.authorization) {
				const localVarAuthorizationValue = typeof configuration.authorization === 'function'
					? configuration.authorization('bearerAuth')
					: configuration.authorization;
				if (localVarAuthorizationValue !== null) {
					localVarHeaderParameter.set("Authorization", "Bearer " + localVarAuthorizationValue);
				}
			}
			localVarHeaderParameter.set('Content-Type', 'application/json');

			localVarRequestOptions.headers = localVarHeaderParameter;
	
			if (request !== undefined) {
				localVarRequestOptions.body = JSON.stringify(request || {});
			}

			const localVarQueryParameterString = localVarQueryParameter.toString();
			if (localVarQueryParameterString) {
				localVarPath += "?" + localVarQueryParameterString;
			}
			return {
				url: localVarPath,
				options: localVarRequestOptions,
			};
		},
		/**
		 * @param {number} recipeId
		 * @param {RequestInit} [options] Override http request option.
		 * @throws {RequiredError}
		 */
		getRecipe(recipeId: number, options: RequestInit = {}): FetchArgs {
			// verify required parameter 'recipeId' is not null or undefined
			if (recipeId === null || recipeId === undefined) {
				throw new RequiredError('recipeId', 'Required parameter recipeId was null or undefined when calling getRecipe.');
			}
			let localVarPath = `/recipe/{recipeId}`
				.replace('{recipeId}', encodeURIComponent(String(recipeId)));
			const localVarPathQueryStart = localVarPath.indexOf("?");
			const localVarRequestOptions: RequestInit = Object.assign({ method: 'GET' }, options);
			const localVarHeaderParameter: Headers = options.headers ? new Headers(options.headers) : new Headers();
			const localVarQueryParameter = new URLSearchParams(localVarPathQueryStart !== -1 ? localVarPath.substring(localVarPathQueryStart + 1) : "");
			if (localVarPathQueryStart !== -1) {
				localVarPath = localVarPath.substring(0, localVarPathQueryStart);
			}

			// authentication bearerAuth required
			// http authorization required
			if (configuration && configuration.authorization) {
				const localVarAuthorizationValue = typeof configuration.authorization === 'function'
					? configuration.authorization('bearerAuth')
					: configuration.authorization;
				if (localVarAuthorizationValue !== null) {
					localVarHeaderParameter.set("Authorization", "Bearer " + localVarAuthorizationValue);
				}
			}
			localVarRequestOptions.headers = localVarHeaderParameter;

			const localVarQueryParameterString = localVarQueryParameter.toString();
			if (localVarQueryParameterString) {
				localVarPath += "?" + localVarQueryParameterString;
			}
			return {
				url: localVarPath,
				options: localVarRequestOptions,
			};
		},
		/**
		 * @param {number} recipeId
		 * @param {Api.UpdateRecipe} request
		 * @param {RequestInit} [options] Override http request option.
		 * @throws {RequiredError}
		 */
		updateRecipe(recipeId: number, request: Api.UpdateRecipe, options: RequestInit = {}): FetchArgs {
			// verify required parameter 'recipeId' is not null or undefined
			if (recipeId === null || recipeId === undefined) {
				throw new RequiredError('recipeId', 'Required parameter recipeId was null or undefined when calling updateRecipe.');
			}
			// verify required parameter 'request' is not null or undefined
			if (request === null || request === undefined) {
				throw new RequiredError('request', 'Required parameter request was null or undefined when calling updateRecipe.');
			}
			let localVarPath = `/recipe/{recipeId}`
				.replace('{recipeId}', encodeURIComponent(String(recipeId)));
			const localVarPathQueryStart = localVarPath.indexOf("?");
			const localVarRequestOptions: RequestInit = Object.assign({ method: 'PUT' }, options);
			const localVarHeaderParameter: Headers = options.headers ? new Headers(options.headers) : new Headers();
			const localVarQueryParameter = new URLSearchParams(localVarPathQueryStart !== -1 ? localVarPath.substring(localVarPathQueryStart + 1) : "");
			if (localVarPathQueryStart !== -1) {
				localVarPath = localVarPath.substring(0, localVarPathQueryStart);
			}

			// authentication bearerAuth required
			// http authorization required
			if (configuration && configuration.authorization) {
				const localVarAuthorizationValue = typeof configuration.authorization === 'function'
					? configuration.authorization('bearerAuth')
					: configuration.authorization;
				if (localVarAuthorizationValue !== null) {
					localVarHeaderParameter.set("Authorization", "Bearer " + localVarAuthorizationValue);
				}
			}
			localVarHeaderParameter.set('Content-Type', 'application/json');

			localVarRequestOptions.headers = localVarHeaderParameter;
	
			if (request !== undefined) {
				localVarRequestOptions.body = JSON.stringify(request || {});
			}

			const localVarQueryParameterString = localVarQueryParameter.toString();
			if (localVarQueryParameterString) {
				localVarPath += "?" + localVarQueryParameterString;
			}
			return {
				url: localVarPath,
				options: localVarRequestOptions,
			};
		},
	}
};

/**
 * RecipeApi - functional programming interface
 * @export
 */
export const RecipeApiFp = function(configuration?: Configuration) {
	return {
		/**
		 * @param {Api.CreateRecipe} request
		 * @param {RequestInit} [options] Override http request option.
		 * @throws {RequiredError}
		 */
		createRecipe(request: Api.CreateRecipe, options?: RequestInit): (fetch?: FetchAPI, basePath?: string) => Promise<Api.CreateRecipe201Response> {
			const localVarFetchArgs = RecipeApiFetchParamCreator(configuration).createRecipe(request, options);
			return (fetch: FetchAPI = defaultFetch, basePath: string = BASE_PATH) => {
				return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
					const contentType = response.headers.get('Content-Type');
					const mimeType = contentType ? contentType.replace(/;.*/, '') : undefined;
					
					if (response.status === 201) {
						if (mimeType === 'application/json') {
							return response.json() as any;
						}
						throw response;
					}
					if (response.status === 401) {
						if (mimeType === 'application/json') {
							throw response;
						}
						throw response;
					}
					if (response.status === 409) {
						if (mimeType === 'application/json') {
							throw response;
						}
						throw response;
					}
					if (response.status === 422) {
						if (mimeType === 'application/json') {
							throw response;
						}
						throw response;
					}
					if (response.status === 500) {
						if (mimeType === 'application/json') {
							throw response;
						}
						throw response;
					}
					throw response;
				});
			};
		},
		/**
		 * @param {number} recipeId
		 * @param {RequestInit} [options] Override http request option.
		 * @throws {RequiredError}
		 */
		deleteRecipe(recipeId: number, options?: RequestInit): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
			const localVarFetchArgs = RecipeApiFetchParamCreator(configuration).deleteRecipe(recipeId, options);
			return (fetch: FetchAPI = defaultFetch, basePath: string = BASE_PATH) => {
				return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
					const contentType = response.headers.get('Content-Type');
					const mimeType = contentType ? contentType.replace(/;.*/, '') : undefined;
					
					if (response.status === 204) {
						return response;
					}
					if (response.status === 401) {
						if (mimeType === 'application/json') {
							throw response;
						}
						throw response;
					}
					if (response.status === 404) {
						if (mimeType === 'application/json') {
							throw response;
						}
						throw response;
					}
					if (response.status === 422) {
						if (mimeType === 'application/json') {
							throw response;
						}
						throw response;
					}
					if (response.status === 500) {
						if (mimeType === 'application/json') {
							throw response;
						}
						throw response;
					}
					throw response;
				});
			};
		},
		/**
		 * @param {Api.RecipeSearchCriteria} request
		 * @param {RequestInit} [options] Override http request option.
		 * @throws {RequiredError}
		 */
		findRecipe(request: Api.RecipeSearchCriteria, options?: RequestInit): (fetch?: FetchAPI, basePath?: string) => Promise<Api.SimpleRecipePage> {
			const localVarFetchArgs = RecipeApiFetchParamCreator(configuration).findRecipe(request, options);
			return (fetch: FetchAPI = defaultFetch, basePath: string = BASE_PATH) => {
				return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
					const contentType = response.headers.get('Content-Type');
					const mimeType = contentType ? contentType.replace(/;.*/, '') : undefined;
					
					if (response.status === 200) {
						if (mimeType === 'application/json') {
							return response.json() as any;
						}
						throw response;
					}
					if (response.status === 401) {
						if (mimeType === 'application/json') {
							throw response;
						}
						throw response;
					}
					if (response.status === 409) {
						if (mimeType === 'application/json') {
							throw response;
						}
						throw response;
					}
					if (response.status === 422) {
						if (mimeType === 'application/json') {
							throw response;
						}
						throw response;
					}
					if (response.status === 500) {
						if (mimeType === 'application/json') {
							throw response;
						}
						throw response;
					}
					throw response;
				});
			};
		},
		/**
		 * @param {number} recipeId
		 * @param {RequestInit} [options] Override http request option.
		 * @throws {RequiredError}
		 */
		getRecipe(recipeId: number, options?: RequestInit): (fetch?: FetchAPI, basePath?: string) => Promise<Api.Recipe> {
			const localVarFetchArgs = RecipeApiFetchParamCreator(configuration).getRecipe(recipeId, options);
			return (fetch: FetchAPI = defaultFetch, basePath: string = BASE_PATH) => {
				return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
					const contentType = response.headers.get('Content-Type');
					const mimeType = contentType ? contentType.replace(/;.*/, '') : undefined;
					
					if (response.status === 200) {
						if (mimeType === 'application/json') {
							return response.json() as any;
						}
						throw response;
					}
					if (response.status === 401) {
						if (mimeType === 'application/json') {
							throw response;
						}
						throw response;
					}
					if (response.status === 404) {
						if (mimeType === 'application/json') {
							throw response;
						}
						throw response;
					}
					if (response.status === 422) {
						if (mimeType === 'application/json') {
							throw response;
						}
						throw response;
					}
					if (response.status === 500) {
						if (mimeType === 'application/json') {
							throw response;
						}
						throw response;
					}
					throw response;
				});
			};
		},
		/**
		 * @param {number} recipeId
		 * @param {Api.UpdateRecipe} request
		 * @param {RequestInit} [options] Override http request option.
		 * @throws {RequiredError}
		 */
		updateRecipe(recipeId: number, request: Api.UpdateRecipe, options?: RequestInit): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
			const localVarFetchArgs = RecipeApiFetchParamCreator(configuration).updateRecipe(recipeId, request, options);
			return (fetch: FetchAPI = defaultFetch, basePath: string = BASE_PATH) => {
				return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
					const contentType = response.headers.get('Content-Type');
					const mimeType = contentType ? contentType.replace(/;.*/, '') : undefined;
					
					if (response.status === 204) {
						return response;
					}
					if (response.status === 401) {
						if (mimeType === 'application/json') {
							throw response;
						}
						throw response;
					}
					if (response.status === 404) {
						if (mimeType === 'application/json') {
							throw response;
						}
						throw response;
					}
					if (response.status === 409) {
						if (mimeType === 'application/json') {
							throw response;
						}
						throw response;
					}
					if (response.status === 422) {
						if (mimeType === 'application/json') {
							throw response;
						}
						throw response;
					}
					if (response.status === 500) {
						if (mimeType === 'application/json') {
							throw response;
						}
						throw response;
					}
					throw response;
				});
			};
		},
	}
};

/**
 * RecipeApi - factory interface
 * @export
 */
export const RecipeApiFactory: FactoryFunction<RecipeApi> = function (configuration?: Configuration, basePath?: string, fetch?: FetchAPI) {
	return new RecipeApi(configuration, basePath, fetch);
};

/**
 * RecipeApi - object-oriented interface
 * @export
 * @class RecipeApi
 * @extends {BaseAPI}
 */
export class RecipeApi extends BaseAPI {
	/**
	 * @param {Api.CreateRecipe} request
	 * @param {RequestInit} [options] Override http request option.
	 * @throws {RequiredError}
	 */
	public createRecipe(request: Api.CreateRecipe, options?: RequestInit) {
		return RecipeApiFp(this.configuration).createRecipe(request, options)(this.fetch, this.basePath);
	}

	/**
	 * @param {number} recipeId
	 * @param {RequestInit} [options] Override http request option.
	 * @throws {RequiredError}
	 */
	public deleteRecipe(recipeId: number, options?: RequestInit) {
		return RecipeApiFp(this.configuration).deleteRecipe(recipeId, options)(this.fetch, this.basePath);
	}

	/**
	 * @param {Api.RecipeSearchCriteria} request
	 * @param {RequestInit} [options] Override http request option.
	 * @throws {RequiredError}
	 */
	public findRecipe(request: Api.RecipeSearchCriteria, options?: RequestInit) {
		return RecipeApiFp(this.configuration).findRecipe(request, options)(this.fetch, this.basePath);
	}

	/**
	 * @param {number} recipeId
	 * @param {RequestInit} [options] Override http request option.
	 * @throws {RequiredError}
	 */
	public getRecipe(recipeId: number, options?: RequestInit) {
		return RecipeApiFp(this.configuration).getRecipe(recipeId, options)(this.fetch, this.basePath);
	}

	/**
	 * @param {number} recipeId
	 * @param {Api.UpdateRecipe} request
	 * @param {RequestInit} [options] Override http request option.
	 * @throws {RequiredError}
	 */
	public updateRecipe(recipeId: number, request: Api.UpdateRecipe, options?: RequestInit) {
		return RecipeApiFp(this.configuration).updateRecipe(recipeId, request, options)(this.fetch, this.basePath);
	}

}
/**
 * TagApi - fetch parameter creator
 * @export
 */
export const TagApiFetchParamCreator = function (configuration?: Configuration) {
	return {
		/**
		 * @param {Api.CreateTag} request
		 * @param {RequestInit} [options] Override http request option.
		 * @throws {RequiredError}
		 */
		createTag(request: Api.CreateTag, options: RequestInit = {}): FetchArgs {
			// verify required parameter 'request' is not null or undefined
			if (request === null || request === undefined) {
				throw new RequiredError('request', 'Required parameter request was null or undefined when calling createTag.');
			}
			let localVarPath = `/tag`;
			const localVarPathQueryStart = localVarPath.indexOf("?");
			const localVarRequestOptions: RequestInit = Object.assign({ method: 'POST' }, options);
			const localVarHeaderParameter: Headers = options.headers ? new Headers(options.headers) : new Headers();
			const localVarQueryParameter = new URLSearchParams(localVarPathQueryStart !== -1 ? localVarPath.substring(localVarPathQueryStart + 1) : "");
			if (localVarPathQueryStart !== -1) {
				localVarPath = localVarPath.substring(0, localVarPathQueryStart);
			}

			// authentication bearerAuth required
			// http authorization required
			if (configuration && configuration.authorization) {
				const localVarAuthorizationValue = typeof configuration.authorization === 'function'
					? configuration.authorization('bearerAuth')
					: configuration.authorization;
				if (localVarAuthorizationValue !== null) {
					localVarHeaderParameter.set("Authorization", "Bearer " + localVarAuthorizationValue);
				}
			}
			localVarHeaderParameter.set('Content-Type', 'application/json');

			localVarRequestOptions.headers = localVarHeaderParameter;
	
			if (request !== undefined) {
				localVarRequestOptions.body = JSON.stringify(request || {});
			}

			const localVarQueryParameterString = localVarQueryParameter.toString();
			if (localVarQueryParameterString) {
				localVarPath += "?" + localVarQueryParameterString;
			}
			return {
				url: localVarPath,
				options: localVarRequestOptions,
			};
		},
		/**
		 * @param {number} tagId
		 * @param {RequestInit} [options] Override http request option.
		 * @throws {RequiredError}
		 */
		deleteTag(tagId: number, options: RequestInit = {}): FetchArgs {
			// verify required parameter 'tagId' is not null or undefined
			if (tagId === null || tagId === undefined) {
				throw new RequiredError('tagId', 'Required parameter tagId was null or undefined when calling deleteTag.');
			}
			let localVarPath = `/tag/{tagId}`
				.replace('{tagId}', encodeURIComponent(String(tagId)));
			const localVarPathQueryStart = localVarPath.indexOf("?");
			const localVarRequestOptions: RequestInit = Object.assign({ method: 'DELETE' }, options);
			const localVarHeaderParameter: Headers = options.headers ? new Headers(options.headers) : new Headers();
			const localVarQueryParameter = new URLSearchParams(localVarPathQueryStart !== -1 ? localVarPath.substring(localVarPathQueryStart + 1) : "");
			if (localVarPathQueryStart !== -1) {
				localVarPath = localVarPath.substring(0, localVarPathQueryStart);
			}

			// authentication bearerAuth required
			// http authorization required
			if (configuration && configuration.authorization) {
				const localVarAuthorizationValue = typeof configuration.authorization === 'function'
					? configuration.authorization('bearerAuth')
					: configuration.authorization;
				if (localVarAuthorizationValue !== null) {
					localVarHeaderParameter.set("Authorization", "Bearer " + localVarAuthorizationValue);
				}
			}
			localVarRequestOptions.headers = localVarHeaderParameter;

			const localVarQueryParameterString = localVarQueryParameter.toString();
			if (localVarQueryParameterString) {
				localVarPath += "?" + localVarQueryParameterString;
			}
			return {
				url: localVarPath,
				options: localVarRequestOptions,
			};
		},
		/**
		 * @param {number} tagId
		 * @param {RequestInit} [options] Override http request option.
		 * @throws {RequiredError}
		 */
		getTag(tagId: number, options: RequestInit = {}): FetchArgs {
			// verify required parameter 'tagId' is not null or undefined
			if (tagId === null || tagId === undefined) {
				throw new RequiredError('tagId', 'Required parameter tagId was null or undefined when calling getTag.');
			}
			let localVarPath = `/tag/{tagId}`
				.replace('{tagId}', encodeURIComponent(String(tagId)));
			const localVarPathQueryStart = localVarPath.indexOf("?");
			const localVarRequestOptions: RequestInit = Object.assign({ method: 'GET' }, options);
			const localVarHeaderParameter: Headers = options.headers ? new Headers(options.headers) : new Headers();
			const localVarQueryParameter = new URLSearchParams(localVarPathQueryStart !== -1 ? localVarPath.substring(localVarPathQueryStart + 1) : "");
			if (localVarPathQueryStart !== -1) {
				localVarPath = localVarPath.substring(0, localVarPathQueryStart);
			}

			// authentication bearerAuth required
			// http authorization required
			if (configuration && configuration.authorization) {
				const localVarAuthorizationValue = typeof configuration.authorization === 'function'
					? configuration.authorization('bearerAuth')
					: configuration.authorization;
				if (localVarAuthorizationValue !== null) {
					localVarHeaderParameter.set("Authorization", "Bearer " + localVarAuthorizationValue);
				}
			}
			localVarRequestOptions.headers = localVarHeaderParameter;

			const localVarQueryParameterString = localVarQueryParameter.toString();
			if (localVarQueryParameterString) {
				localVarPath += "?" + localVarQueryParameterString;
			}
			return {
				url: localVarPath,
				options: localVarRequestOptions,
			};
		},
		/**
		 * @param {RequestInit} [options] Override http request option.
		 * @throws {RequiredError}
		 */
		getTags(options: RequestInit = {}): FetchArgs {
			let localVarPath = `/tag`;
			const localVarPathQueryStart = localVarPath.indexOf("?");
			const localVarRequestOptions: RequestInit = Object.assign({ method: 'GET' }, options);
			const localVarHeaderParameter: Headers = options.headers ? new Headers(options.headers) : new Headers();
			const localVarQueryParameter = new URLSearchParams(localVarPathQueryStart !== -1 ? localVarPath.substring(localVarPathQueryStart + 1) : "");
			if (localVarPathQueryStart !== -1) {
				localVarPath = localVarPath.substring(0, localVarPathQueryStart);
			}

			// authentication bearerAuth required
			// http authorization required
			if (configuration && configuration.authorization) {
				const localVarAuthorizationValue = typeof configuration.authorization === 'function'
					? configuration.authorization('bearerAuth')
					: configuration.authorization;
				if (localVarAuthorizationValue !== null) {
					localVarHeaderParameter.set("Authorization", "Bearer " + localVarAuthorizationValue);
				}
			}
			localVarRequestOptions.headers = localVarHeaderParameter;

			const localVarQueryParameterString = localVarQueryParameter.toString();
			if (localVarQueryParameterString) {
				localVarPath += "?" + localVarQueryParameterString;
			}
			return {
				url: localVarPath,
				options: localVarRequestOptions,
			};
		},
		/**
		 * @param {number} tagId
		 * @param {Api.UpdateTag} request
		 * @param {RequestInit} [options] Override http request option.
		 * @throws {RequiredError}
		 */
		updateTag(tagId: number, request: Api.UpdateTag, options: RequestInit = {}): FetchArgs {
			// verify required parameter 'tagId' is not null or undefined
			if (tagId === null || tagId === undefined) {
				throw new RequiredError('tagId', 'Required parameter tagId was null or undefined when calling updateTag.');
			}
			// verify required parameter 'request' is not null or undefined
			if (request === null || request === undefined) {
				throw new RequiredError('request', 'Required parameter request was null or undefined when calling updateTag.');
			}
			let localVarPath = `/tag/{tagId}`
				.replace('{tagId}', encodeURIComponent(String(tagId)));
			const localVarPathQueryStart = localVarPath.indexOf("?");
			const localVarRequestOptions: RequestInit = Object.assign({ method: 'PUT' }, options);
			const localVarHeaderParameter: Headers = options.headers ? new Headers(options.headers) : new Headers();
			const localVarQueryParameter = new URLSearchParams(localVarPathQueryStart !== -1 ? localVarPath.substring(localVarPathQueryStart + 1) : "");
			if (localVarPathQueryStart !== -1) {
				localVarPath = localVarPath.substring(0, localVarPathQueryStart);
			}

			// authentication bearerAuth required
			// http authorization required
			if (configuration && configuration.authorization) {
				const localVarAuthorizationValue = typeof configuration.authorization === 'function'
					? configuration.authorization('bearerAuth')
					: configuration.authorization;
				if (localVarAuthorizationValue !== null) {
					localVarHeaderParameter.set("Authorization", "Bearer " + localVarAuthorizationValue);
				}
			}
			localVarHeaderParameter.set('Content-Type', 'application/json');

			localVarRequestOptions.headers = localVarHeaderParameter;
	
			if (request !== undefined) {
				localVarRequestOptions.body = JSON.stringify(request || {});
			}

			const localVarQueryParameterString = localVarQueryParameter.toString();
			if (localVarQueryParameterString) {
				localVarPath += "?" + localVarQueryParameterString;
			}
			return {
				url: localVarPath,
				options: localVarRequestOptions,
			};
		},
	}
};

/**
 * TagApi - functional programming interface
 * @export
 */
export const TagApiFp = function(configuration?: Configuration) {
	return {
		/**
		 * @param {Api.CreateTag} request
		 * @param {RequestInit} [options] Override http request option.
		 * @throws {RequiredError}
		 */
		createTag(request: Api.CreateTag, options?: RequestInit): (fetch?: FetchAPI, basePath?: string) => Promise<Api.Tag> {
			const localVarFetchArgs = TagApiFetchParamCreator(configuration).createTag(request, options);
			return (fetch: FetchAPI = defaultFetch, basePath: string = BASE_PATH) => {
				return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
					const contentType = response.headers.get('Content-Type');
					const mimeType = contentType ? contentType.replace(/;.*/, '') : undefined;
					
					if (response.status === 201) {
						if (mimeType === 'application/json') {
							return response.json() as any;
						}
						throw response;
					}
					if (response.status === 401) {
						if (mimeType === 'application/json') {
							throw response;
						}
						throw response;
					}
					if (response.status === 409) {
						if (mimeType === 'application/json') {
							throw response;
						}
						throw response;
					}
					if (response.status === 422) {
						if (mimeType === 'application/json') {
							throw response;
						}
						throw response;
					}
					if (response.status === 500) {
						if (mimeType === 'application/json') {
							throw response;
						}
						throw response;
					}
					throw response;
				});
			};
		},
		/**
		 * @param {number} tagId
		 * @param {RequestInit} [options] Override http request option.
		 * @throws {RequiredError}
		 */
		deleteTag(tagId: number, options?: RequestInit): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
			const localVarFetchArgs = TagApiFetchParamCreator(configuration).deleteTag(tagId, options);
			return (fetch: FetchAPI = defaultFetch, basePath: string = BASE_PATH) => {
				return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
					const contentType = response.headers.get('Content-Type');
					const mimeType = contentType ? contentType.replace(/;.*/, '') : undefined;
					
					if (response.status === 204) {
						return response;
					}
					if (response.status === 401) {
						if (mimeType === 'application/json') {
							throw response;
						}
						throw response;
					}
					if (response.status === 404) {
						if (mimeType === 'application/json') {
							throw response;
						}
						throw response;
					}
					if (response.status === 409) {
						if (mimeType === 'application/json') {
							throw response;
						}
						throw response;
					}
					if (response.status === 422) {
						if (mimeType === 'application/json') {
							throw response;
						}
						throw response;
					}
					if (response.status === 500) {
						if (mimeType === 'application/json') {
							throw response;
						}
						throw response;
					}
					throw response;
				});
			};
		},
		/**
		 * @param {number} tagId
		 * @param {RequestInit} [options] Override http request option.
		 * @throws {RequiredError}
		 */
		getTag(tagId: number, options?: RequestInit): (fetch?: FetchAPI, basePath?: string) => Promise<Api.Tag> {
			const localVarFetchArgs = TagApiFetchParamCreator(configuration).getTag(tagId, options);
			return (fetch: FetchAPI = defaultFetch, basePath: string = BASE_PATH) => {
				return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
					const contentType = response.headers.get('Content-Type');
					const mimeType = contentType ? contentType.replace(/;.*/, '') : undefined;
					
					if (response.status === 200) {
						if (mimeType === 'application/json') {
							return response.json() as any;
						}
						throw response;
					}
					if (response.status === 401) {
						if (mimeType === 'application/json') {
							throw response;
						}
						throw response;
					}
					if (response.status === 404) {
						if (mimeType === 'application/json') {
							throw response;
						}
						throw response;
					}
					if (response.status === 422) {
						if (mimeType === 'application/json') {
							throw response;
						}
						throw response;
					}
					if (response.status === 500) {
						if (mimeType === 'application/json') {
							throw response;
						}
						throw response;
					}
					throw response;
				});
			};
		},
		/**
		 * @param {RequestInit} [options] Override http request option.
		 * @throws {RequiredError}
		 */
		getTags(options?: RequestInit): (fetch?: FetchAPI, basePath?: string) => Promise<Api.SimpleTag[]> {
			const localVarFetchArgs = TagApiFetchParamCreator(configuration).getTags(options);
			return (fetch: FetchAPI = defaultFetch, basePath: string = BASE_PATH) => {
				return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
					const contentType = response.headers.get('Content-Type');
					const mimeType = contentType ? contentType.replace(/;.*/, '') : undefined;
					
					if (response.status === 200) {
						if (mimeType === 'application/json') {
							return response.json() as any;
						}
						throw response;
					}
					if (response.status === 401) {
						if (mimeType === 'application/json') {
							throw response;
						}
						throw response;
					}
					if (response.status === 500) {
						if (mimeType === 'application/json') {
							throw response;
						}
						throw response;
					}
					throw response;
				});
			};
		},
		/**
		 * @param {number} tagId
		 * @param {Api.UpdateTag} request
		 * @param {RequestInit} [options] Override http request option.
		 * @throws {RequiredError}
		 */
		updateTag(tagId: number, request: Api.UpdateTag, options?: RequestInit): (fetch?: FetchAPI, basePath?: string) => Promise<Api.Tag> {
			const localVarFetchArgs = TagApiFetchParamCreator(configuration).updateTag(tagId, request, options);
			return (fetch: FetchAPI = defaultFetch, basePath: string = BASE_PATH) => {
				return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
					const contentType = response.headers.get('Content-Type');
					const mimeType = contentType ? contentType.replace(/;.*/, '') : undefined;
					
					if (response.status === 200) {
						if (mimeType === 'application/json') {
							return response.json() as any;
						}
						throw response;
					}
					if (response.status === 401) {
						if (mimeType === 'application/json') {
							throw response;
						}
						throw response;
					}
					if (response.status === 404) {
						if (mimeType === 'application/json') {
							throw response;
						}
						throw response;
					}
					if (response.status === 409) {
						if (mimeType === 'application/json') {
							throw response;
						}
						throw response;
					}
					if (response.status === 422) {
						if (mimeType === 'application/json') {
							throw response;
						}
						throw response;
					}
					if (response.status === 500) {
						if (mimeType === 'application/json') {
							throw response;
						}
						throw response;
					}
					throw response;
				});
			};
		},
	}
};

/**
 * TagApi - factory interface
 * @export
 */
export const TagApiFactory: FactoryFunction<TagApi> = function (configuration?: Configuration, basePath?: string, fetch?: FetchAPI) {
	return new TagApi(configuration, basePath, fetch);
};

/**
 * TagApi - object-oriented interface
 * @export
 * @class TagApi
 * @extends {BaseAPI}
 */
export class TagApi extends BaseAPI {
	/**
	 * @param {Api.CreateTag} request
	 * @param {RequestInit} [options] Override http request option.
	 * @throws {RequiredError}
	 */
	public createTag(request: Api.CreateTag, options?: RequestInit) {
		return TagApiFp(this.configuration).createTag(request, options)(this.fetch, this.basePath);
	}

	/**
	 * @param {number} tagId
	 * @param {RequestInit} [options] Override http request option.
	 * @throws {RequiredError}
	 */
	public deleteTag(tagId: number, options?: RequestInit) {
		return TagApiFp(this.configuration).deleteTag(tagId, options)(this.fetch, this.basePath);
	}

	/**
	 * @param {number} tagId
	 * @param {RequestInit} [options] Override http request option.
	 * @throws {RequiredError}
	 */
	public getTag(tagId: number, options?: RequestInit) {
		return TagApiFp(this.configuration).getTag(tagId, options)(this.fetch, this.basePath);
	}

	/**
	 * @param {RequestInit} [options] Override http request option.
	 * @throws {RequiredError}
	 */
	public getTags(options?: RequestInit) {
		return TagApiFp(this.configuration).getTags(options)(this.fetch, this.basePath);
	}

	/**
	 * @param {number} tagId
	 * @param {Api.UpdateTag} request
	 * @param {RequestInit} [options] Override http request option.
	 * @throws {RequiredError}
	 */
	public updateTag(tagId: number, request: Api.UpdateTag, options?: RequestInit) {
		return TagApiFp(this.configuration).updateTag(tagId, request, options)(this.fetch, this.basePath);
	}

}
/**
 * UnitApi - fetch parameter creator
 * @export
 */
export const UnitApiFetchParamCreator = function (configuration?: Configuration) {
	return {
		/**
		 * @param {Api.CreateUnit} request
		 * @param {RequestInit} [options] Override http request option.
		 * @throws {RequiredError}
		 */
		createUnit(request: Api.CreateUnit, options: RequestInit = {}): FetchArgs {
			// verify required parameter 'request' is not null or undefined
			if (request === null || request === undefined) {
				throw new RequiredError('request', 'Required parameter request was null or undefined when calling createUnit.');
			}
			let localVarPath = `/unit`;
			const localVarPathQueryStart = localVarPath.indexOf("?");
			const localVarRequestOptions: RequestInit = Object.assign({ method: 'POST' }, options);
			const localVarHeaderParameter: Headers = options.headers ? new Headers(options.headers) : new Headers();
			const localVarQueryParameter = new URLSearchParams(localVarPathQueryStart !== -1 ? localVarPath.substring(localVarPathQueryStart + 1) : "");
			if (localVarPathQueryStart !== -1) {
				localVarPath = localVarPath.substring(0, localVarPathQueryStart);
			}

			// authentication bearerAuth required
			// http authorization required
			if (configuration && configuration.authorization) {
				const localVarAuthorizationValue = typeof configuration.authorization === 'function'
					? configuration.authorization('bearerAuth')
					: configuration.authorization;
				if (localVarAuthorizationValue !== null) {
					localVarHeaderParameter.set("Authorization", "Bearer " + localVarAuthorizationValue);
				}
			}
			localVarHeaderParameter.set('Content-Type', 'application/json');

			localVarRequestOptions.headers = localVarHeaderParameter;
	
			if (request !== undefined) {
				localVarRequestOptions.body = JSON.stringify(request || {});
			}

			const localVarQueryParameterString = localVarQueryParameter.toString();
			if (localVarQueryParameterString) {
				localVarPath += "?" + localVarQueryParameterString;
			}
			return {
				url: localVarPath,
				options: localVarRequestOptions,
			};
		},
		/**
		 * @param {number} unitId
		 * @param {RequestInit} [options] Override http request option.
		 * @throws {RequiredError}
		 */
		deleteUnit(unitId: number, options: RequestInit = {}): FetchArgs {
			// verify required parameter 'unitId' is not null or undefined
			if (unitId === null || unitId === undefined) {
				throw new RequiredError('unitId', 'Required parameter unitId was null or undefined when calling deleteUnit.');
			}
			let localVarPath = `/unit/{unitId}`
				.replace('{unitId}', encodeURIComponent(String(unitId)));
			const localVarPathQueryStart = localVarPath.indexOf("?");
			const localVarRequestOptions: RequestInit = Object.assign({ method: 'DELETE' }, options);
			const localVarHeaderParameter: Headers = options.headers ? new Headers(options.headers) : new Headers();
			const localVarQueryParameter = new URLSearchParams(localVarPathQueryStart !== -1 ? localVarPath.substring(localVarPathQueryStart + 1) : "");
			if (localVarPathQueryStart !== -1) {
				localVarPath = localVarPath.substring(0, localVarPathQueryStart);
			}

			// authentication bearerAuth required
			// http authorization required
			if (configuration && configuration.authorization) {
				const localVarAuthorizationValue = typeof configuration.authorization === 'function'
					? configuration.authorization('bearerAuth')
					: configuration.authorization;
				if (localVarAuthorizationValue !== null) {
					localVarHeaderParameter.set("Authorization", "Bearer " + localVarAuthorizationValue);
				}
			}
			localVarRequestOptions.headers = localVarHeaderParameter;

			const localVarQueryParameterString = localVarQueryParameter.toString();
			if (localVarQueryParameterString) {
				localVarPath += "?" + localVarQueryParameterString;
			}
			return {
				url: localVarPath,
				options: localVarRequestOptions,
			};
		},
		/**
		 * @param {number} unitId
		 * @param {RequestInit} [options] Override http request option.
		 * @throws {RequiredError}
		 */
		getUnit(unitId: number, options: RequestInit = {}): FetchArgs {
			// verify required parameter 'unitId' is not null or undefined
			if (unitId === null || unitId === undefined) {
				throw new RequiredError('unitId', 'Required parameter unitId was null or undefined when calling getUnit.');
			}
			let localVarPath = `/unit/{unitId}`
				.replace('{unitId}', encodeURIComponent(String(unitId)));
			const localVarPathQueryStart = localVarPath.indexOf("?");
			const localVarRequestOptions: RequestInit = Object.assign({ method: 'GET' }, options);
			const localVarHeaderParameter: Headers = options.headers ? new Headers(options.headers) : new Headers();
			const localVarQueryParameter = new URLSearchParams(localVarPathQueryStart !== -1 ? localVarPath.substring(localVarPathQueryStart + 1) : "");
			if (localVarPathQueryStart !== -1) {
				localVarPath = localVarPath.substring(0, localVarPathQueryStart);
			}

			// authentication bearerAuth required
			// http authorization required
			if (configuration && configuration.authorization) {
				const localVarAuthorizationValue = typeof configuration.authorization === 'function'
					? configuration.authorization('bearerAuth')
					: configuration.authorization;
				if (localVarAuthorizationValue !== null) {
					localVarHeaderParameter.set("Authorization", "Bearer " + localVarAuthorizationValue);
				}
			}
			localVarRequestOptions.headers = localVarHeaderParameter;

			const localVarQueryParameterString = localVarQueryParameter.toString();
			if (localVarQueryParameterString) {
				localVarPath += "?" + localVarQueryParameterString;
			}
			return {
				url: localVarPath,
				options: localVarRequestOptions,
			};
		},
		/**
		 * @param {number} unitCategoryId
		 * @param {RequestInit} [options] Override http request option.
		 * @throws {RequiredError}
		 */
		getUnitsByUnitCategory(unitCategoryId: number, options: RequestInit = {}): FetchArgs {
			// verify required parameter 'unitCategoryId' is not null or undefined
			if (unitCategoryId === null || unitCategoryId === undefined) {
				throw new RequiredError('unitCategoryId', 'Required parameter unitCategoryId was null or undefined when calling getUnitsByUnitCategory.');
			}
			let localVarPath = `/unit/byUnitCategory/{unitCategoryId}`
				.replace('{unitCategoryId}', encodeURIComponent(String(unitCategoryId)));
			const localVarPathQueryStart = localVarPath.indexOf("?");
			const localVarRequestOptions: RequestInit = Object.assign({ method: 'GET' }, options);
			const localVarHeaderParameter: Headers = options.headers ? new Headers(options.headers) : new Headers();
			const localVarQueryParameter = new URLSearchParams(localVarPathQueryStart !== -1 ? localVarPath.substring(localVarPathQueryStart + 1) : "");
			if (localVarPathQueryStart !== -1) {
				localVarPath = localVarPath.substring(0, localVarPathQueryStart);
			}

			// authentication bearerAuth required
			// http authorization required
			if (configuration && configuration.authorization) {
				const localVarAuthorizationValue = typeof configuration.authorization === 'function'
					? configuration.authorization('bearerAuth')
					: configuration.authorization;
				if (localVarAuthorizationValue !== null) {
					localVarHeaderParameter.set("Authorization", "Bearer " + localVarAuthorizationValue);
				}
			}
			localVarRequestOptions.headers = localVarHeaderParameter;

			const localVarQueryParameterString = localVarQueryParameter.toString();
			if (localVarQueryParameterString) {
				localVarPath += "?" + localVarQueryParameterString;
			}
			return {
				url: localVarPath,
				options: localVarRequestOptions,
			};
		},
		/**
		 * @param {number} unitId
		 * @param {Api.UpdateUnit} request
		 * @param {RequestInit} [options] Override http request option.
		 * @throws {RequiredError}
		 */
		updateUnit(unitId: number, request: Api.UpdateUnit, options: RequestInit = {}): FetchArgs {
			// verify required parameter 'unitId' is not null or undefined
			if (unitId === null || unitId === undefined) {
				throw new RequiredError('unitId', 'Required parameter unitId was null or undefined when calling updateUnit.');
			}
			// verify required parameter 'request' is not null or undefined
			if (request === null || request === undefined) {
				throw new RequiredError('request', 'Required parameter request was null or undefined when calling updateUnit.');
			}
			let localVarPath = `/unit/{unitId}`
				.replace('{unitId}', encodeURIComponent(String(unitId)));
			const localVarPathQueryStart = localVarPath.indexOf("?");
			const localVarRequestOptions: RequestInit = Object.assign({ method: 'PUT' }, options);
			const localVarHeaderParameter: Headers = options.headers ? new Headers(options.headers) : new Headers();
			const localVarQueryParameter = new URLSearchParams(localVarPathQueryStart !== -1 ? localVarPath.substring(localVarPathQueryStart + 1) : "");
			if (localVarPathQueryStart !== -1) {
				localVarPath = localVarPath.substring(0, localVarPathQueryStart);
			}

			// authentication bearerAuth required
			// http authorization required
			if (configuration && configuration.authorization) {
				const localVarAuthorizationValue = typeof configuration.authorization === 'function'
					? configuration.authorization('bearerAuth')
					: configuration.authorization;
				if (localVarAuthorizationValue !== null) {
					localVarHeaderParameter.set("Authorization", "Bearer " + localVarAuthorizationValue);
				}
			}
			localVarHeaderParameter.set('Content-Type', 'application/json');

			localVarRequestOptions.headers = localVarHeaderParameter;
	
			if (request !== undefined) {
				localVarRequestOptions.body = JSON.stringify(request || {});
			}

			const localVarQueryParameterString = localVarQueryParameter.toString();
			if (localVarQueryParameterString) {
				localVarPath += "?" + localVarQueryParameterString;
			}
			return {
				url: localVarPath,
				options: localVarRequestOptions,
			};
		},
	}
};

/**
 * UnitApi - functional programming interface
 * @export
 */
export const UnitApiFp = function(configuration?: Configuration) {
	return {
		/**
		 * @param {Api.CreateUnit} request
		 * @param {RequestInit} [options] Override http request option.
		 * @throws {RequiredError}
		 */
		createUnit(request: Api.CreateUnit, options?: RequestInit): (fetch?: FetchAPI, basePath?: string) => Promise<Api.Unit> {
			const localVarFetchArgs = UnitApiFetchParamCreator(configuration).createUnit(request, options);
			return (fetch: FetchAPI = defaultFetch, basePath: string = BASE_PATH) => {
				return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
					const contentType = response.headers.get('Content-Type');
					const mimeType = contentType ? contentType.replace(/;.*/, '') : undefined;
					
					if (response.status === 201) {
						if (mimeType === 'application/json') {
							return response.json() as any;
						}
						throw response;
					}
					if (response.status === 401) {
						if (mimeType === 'application/json') {
							throw response;
						}
						throw response;
					}
					if (response.status === 409) {
						if (mimeType === 'application/json') {
							throw response;
						}
						throw response;
					}
					if (response.status === 422) {
						if (mimeType === 'application/json') {
							throw response;
						}
						throw response;
					}
					if (response.status === 500) {
						if (mimeType === 'application/json') {
							throw response;
						}
						throw response;
					}
					throw response;
				});
			};
		},
		/**
		 * @param {number} unitId
		 * @param {RequestInit} [options] Override http request option.
		 * @throws {RequiredError}
		 */
		deleteUnit(unitId: number, options?: RequestInit): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
			const localVarFetchArgs = UnitApiFetchParamCreator(configuration).deleteUnit(unitId, options);
			return (fetch: FetchAPI = defaultFetch, basePath: string = BASE_PATH) => {
				return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
					const contentType = response.headers.get('Content-Type');
					const mimeType = contentType ? contentType.replace(/;.*/, '') : undefined;
					
					if (response.status === 204) {
						return response;
					}
					if (response.status === 401) {
						if (mimeType === 'application/json') {
							throw response;
						}
						throw response;
					}
					if (response.status === 404) {
						if (mimeType === 'application/json') {
							throw response;
						}
						throw response;
					}
					if (response.status === 422) {
						if (mimeType === 'application/json') {
							throw response;
						}
						throw response;
					}
					if (response.status === 500) {
						if (mimeType === 'application/json') {
							throw response;
						}
						throw response;
					}
					throw response;
				});
			};
		},
		/**
		 * @param {number} unitId
		 * @param {RequestInit} [options] Override http request option.
		 * @throws {RequiredError}
		 */
		getUnit(unitId: number, options?: RequestInit): (fetch?: FetchAPI, basePath?: string) => Promise<Api.Unit> {
			const localVarFetchArgs = UnitApiFetchParamCreator(configuration).getUnit(unitId, options);
			return (fetch: FetchAPI = defaultFetch, basePath: string = BASE_PATH) => {
				return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
					const contentType = response.headers.get('Content-Type');
					const mimeType = contentType ? contentType.replace(/;.*/, '') : undefined;
					
					if (response.status === 200) {
						if (mimeType === 'application/json') {
							return response.json() as any;
						}
						throw response;
					}
					if (response.status === 401) {
						if (mimeType === 'application/json') {
							throw response;
						}
						throw response;
					}
					if (response.status === 404) {
						if (mimeType === 'application/json') {
							throw response;
						}
						throw response;
					}
					if (response.status === 422) {
						if (mimeType === 'application/json') {
							throw response;
						}
						throw response;
					}
					if (response.status === 500) {
						if (mimeType === 'application/json') {
							throw response;
						}
						throw response;
					}
					throw response;
				});
			};
		},
		/**
		 * @param {number} unitCategoryId
		 * @param {RequestInit} [options] Override http request option.
		 * @throws {RequiredError}
		 */
		getUnitsByUnitCategory(unitCategoryId: number, options?: RequestInit): (fetch?: FetchAPI, basePath?: string) => Promise<Api.SimpleUnit[]> {
			const localVarFetchArgs = UnitApiFetchParamCreator(configuration).getUnitsByUnitCategory(unitCategoryId, options);
			return (fetch: FetchAPI = defaultFetch, basePath: string = BASE_PATH) => {
				return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
					const contentType = response.headers.get('Content-Type');
					const mimeType = contentType ? contentType.replace(/;.*/, '') : undefined;
					
					if (response.status === 200) {
						if (mimeType === 'application/json') {
							return response.json() as any;
						}
						throw response;
					}
					if (response.status === 401) {
						if (mimeType === 'application/json') {
							throw response;
						}
						throw response;
					}
					if (response.status === 422) {
						if (mimeType === 'application/json') {
							throw response;
						}
						throw response;
					}
					if (response.status === 500) {
						if (mimeType === 'application/json') {
							throw response;
						}
						throw response;
					}
					throw response;
				});
			};
		},
		/**
		 * @param {number} unitId
		 * @param {Api.UpdateUnit} request
		 * @param {RequestInit} [options] Override http request option.
		 * @throws {RequiredError}
		 */
		updateUnit(unitId: number, request: Api.UpdateUnit, options?: RequestInit): (fetch?: FetchAPI, basePath?: string) => Promise<Api.Unit> {
			const localVarFetchArgs = UnitApiFetchParamCreator(configuration).updateUnit(unitId, request, options);
			return (fetch: FetchAPI = defaultFetch, basePath: string = BASE_PATH) => {
				return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
					const contentType = response.headers.get('Content-Type');
					const mimeType = contentType ? contentType.replace(/;.*/, '') : undefined;
					
					if (response.status === 200) {
						if (mimeType === 'application/json') {
							return response.json() as any;
						}
						throw response;
					}
					if (response.status === 401) {
						if (mimeType === 'application/json') {
							throw response;
						}
						throw response;
					}
					if (response.status === 404) {
						if (mimeType === 'application/json') {
							throw response;
						}
						throw response;
					}
					if (response.status === 409) {
						if (mimeType === 'application/json') {
							throw response;
						}
						throw response;
					}
					if (response.status === 422) {
						if (mimeType === 'application/json') {
							throw response;
						}
						throw response;
					}
					if (response.status === 500) {
						if (mimeType === 'application/json') {
							throw response;
						}
						throw response;
					}
					throw response;
				});
			};
		},
	}
};

/**
 * UnitApi - factory interface
 * @export
 */
export const UnitApiFactory: FactoryFunction<UnitApi> = function (configuration?: Configuration, basePath?: string, fetch?: FetchAPI) {
	return new UnitApi(configuration, basePath, fetch);
};

/**
 * UnitApi - object-oriented interface
 * @export
 * @class UnitApi
 * @extends {BaseAPI}
 */
export class UnitApi extends BaseAPI {
	/**
	 * @param {Api.CreateUnit} request
	 * @param {RequestInit} [options] Override http request option.
	 * @throws {RequiredError}
	 */
	public createUnit(request: Api.CreateUnit, options?: RequestInit) {
		return UnitApiFp(this.configuration).createUnit(request, options)(this.fetch, this.basePath);
	}

	/**
	 * @param {number} unitId
	 * @param {RequestInit} [options] Override http request option.
	 * @throws {RequiredError}
	 */
	public deleteUnit(unitId: number, options?: RequestInit) {
		return UnitApiFp(this.configuration).deleteUnit(unitId, options)(this.fetch, this.basePath);
	}

	/**
	 * @param {number} unitId
	 * @param {RequestInit} [options] Override http request option.
	 * @throws {RequiredError}
	 */
	public getUnit(unitId: number, options?: RequestInit) {
		return UnitApiFp(this.configuration).getUnit(unitId, options)(this.fetch, this.basePath);
	}

	/**
	 * @param {number} unitCategoryId
	 * @param {RequestInit} [options] Override http request option.
	 * @throws {RequiredError}
	 */
	public getUnitsByUnitCategory(unitCategoryId: number, options?: RequestInit) {
		return UnitApiFp(this.configuration).getUnitsByUnitCategory(unitCategoryId, options)(this.fetch, this.basePath);
	}

	/**
	 * @param {number} unitId
	 * @param {Api.UpdateUnit} request
	 * @param {RequestInit} [options] Override http request option.
	 * @throws {RequiredError}
	 */
	public updateUnit(unitId: number, request: Api.UpdateUnit, options?: RequestInit) {
		return UnitApiFp(this.configuration).updateUnit(unitId, request, options)(this.fetch, this.basePath);
	}

}
/**
 * UnitCategoryApi - fetch parameter creator
 * @export
 */
export const UnitCategoryApiFetchParamCreator = function (configuration?: Configuration) {
	return {
		/**
		 * @param {Api.CreateUnitCategory} request
		 * @param {RequestInit} [options] Override http request option.
		 * @throws {RequiredError}
		 */
		createUnitCategory(request: Api.CreateUnitCategory, options: RequestInit = {}): FetchArgs {
			// verify required parameter 'request' is not null or undefined
			if (request === null || request === undefined) {
				throw new RequiredError('request', 'Required parameter request was null or undefined when calling createUnitCategory.');
			}
			let localVarPath = `/unitCategory`;
			const localVarPathQueryStart = localVarPath.indexOf("?");
			const localVarRequestOptions: RequestInit = Object.assign({ method: 'POST' }, options);
			const localVarHeaderParameter: Headers = options.headers ? new Headers(options.headers) : new Headers();
			const localVarQueryParameter = new URLSearchParams(localVarPathQueryStart !== -1 ? localVarPath.substring(localVarPathQueryStart + 1) : "");
			if (localVarPathQueryStart !== -1) {
				localVarPath = localVarPath.substring(0, localVarPathQueryStart);
			}

			// authentication bearerAuth required
			// http authorization required
			if (configuration && configuration.authorization) {
				const localVarAuthorizationValue = typeof configuration.authorization === 'function'
					? configuration.authorization('bearerAuth')
					: configuration.authorization;
				if (localVarAuthorizationValue !== null) {
					localVarHeaderParameter.set("Authorization", "Bearer " + localVarAuthorizationValue);
				}
			}
			localVarHeaderParameter.set('Content-Type', 'application/json');

			localVarRequestOptions.headers = localVarHeaderParameter;
	
			if (request !== undefined) {
				localVarRequestOptions.body = JSON.stringify(request || {});
			}

			const localVarQueryParameterString = localVarQueryParameter.toString();
			if (localVarQueryParameterString) {
				localVarPath += "?" + localVarQueryParameterString;
			}
			return {
				url: localVarPath,
				options: localVarRequestOptions,
			};
		},
		/**
		 * @param {number} unitCategoryId
		 * @param {RequestInit} [options] Override http request option.
		 * @throws {RequiredError}
		 */
		deleteUnitCategory(unitCategoryId: number, options: RequestInit = {}): FetchArgs {
			// verify required parameter 'unitCategoryId' is not null or undefined
			if (unitCategoryId === null || unitCategoryId === undefined) {
				throw new RequiredError('unitCategoryId', 'Required parameter unitCategoryId was null or undefined when calling deleteUnitCategory.');
			}
			let localVarPath = `/unitCategory/{unitCategoryId}`
				.replace('{unitCategoryId}', encodeURIComponent(String(unitCategoryId)));
			const localVarPathQueryStart = localVarPath.indexOf("?");
			const localVarRequestOptions: RequestInit = Object.assign({ method: 'DELETE' }, options);
			const localVarHeaderParameter: Headers = options.headers ? new Headers(options.headers) : new Headers();
			const localVarQueryParameter = new URLSearchParams(localVarPathQueryStart !== -1 ? localVarPath.substring(localVarPathQueryStart + 1) : "");
			if (localVarPathQueryStart !== -1) {
				localVarPath = localVarPath.substring(0, localVarPathQueryStart);
			}

			// authentication bearerAuth required
			// http authorization required
			if (configuration && configuration.authorization) {
				const localVarAuthorizationValue = typeof configuration.authorization === 'function'
					? configuration.authorization('bearerAuth')
					: configuration.authorization;
				if (localVarAuthorizationValue !== null) {
					localVarHeaderParameter.set("Authorization", "Bearer " + localVarAuthorizationValue);
				}
			}
			localVarRequestOptions.headers = localVarHeaderParameter;

			const localVarQueryParameterString = localVarQueryParameter.toString();
			if (localVarQueryParameterString) {
				localVarPath += "?" + localVarQueryParameterString;
			}
			return {
				url: localVarPath,
				options: localVarRequestOptions,
			};
		},
		/**
		 * @param {RequestInit} [options] Override http request option.
		 * @throws {RequiredError}
		 */
		getUnitCategories(options: RequestInit = {}): FetchArgs {
			let localVarPath = `/unitCategory`;
			const localVarPathQueryStart = localVarPath.indexOf("?");
			const localVarRequestOptions: RequestInit = Object.assign({ method: 'GET' }, options);
			const localVarHeaderParameter: Headers = options.headers ? new Headers(options.headers) : new Headers();
			const localVarQueryParameter = new URLSearchParams(localVarPathQueryStart !== -1 ? localVarPath.substring(localVarPathQueryStart + 1) : "");
			if (localVarPathQueryStart !== -1) {
				localVarPath = localVarPath.substring(0, localVarPathQueryStart);
			}

			// authentication bearerAuth required
			// http authorization required
			if (configuration && configuration.authorization) {
				const localVarAuthorizationValue = typeof configuration.authorization === 'function'
					? configuration.authorization('bearerAuth')
					: configuration.authorization;
				if (localVarAuthorizationValue !== null) {
					localVarHeaderParameter.set("Authorization", "Bearer " + localVarAuthorizationValue);
				}
			}
			localVarRequestOptions.headers = localVarHeaderParameter;

			const localVarQueryParameterString = localVarQueryParameter.toString();
			if (localVarQueryParameterString) {
				localVarPath += "?" + localVarQueryParameterString;
			}
			return {
				url: localVarPath,
				options: localVarRequestOptions,
			};
		},
		/**
		 * @param {number} unitCategoryId
		 * @param {RequestInit} [options] Override http request option.
		 * @throws {RequiredError}
		 */
		getUnitCategory(unitCategoryId: number, options: RequestInit = {}): FetchArgs {
			// verify required parameter 'unitCategoryId' is not null or undefined
			if (unitCategoryId === null || unitCategoryId === undefined) {
				throw new RequiredError('unitCategoryId', 'Required parameter unitCategoryId was null or undefined when calling getUnitCategory.');
			}
			let localVarPath = `/unitCategory/{unitCategoryId}`
				.replace('{unitCategoryId}', encodeURIComponent(String(unitCategoryId)));
			const localVarPathQueryStart = localVarPath.indexOf("?");
			const localVarRequestOptions: RequestInit = Object.assign({ method: 'GET' }, options);
			const localVarHeaderParameter: Headers = options.headers ? new Headers(options.headers) : new Headers();
			const localVarQueryParameter = new URLSearchParams(localVarPathQueryStart !== -1 ? localVarPath.substring(localVarPathQueryStart + 1) : "");
			if (localVarPathQueryStart !== -1) {
				localVarPath = localVarPath.substring(0, localVarPathQueryStart);
			}

			// authentication bearerAuth required
			// http authorization required
			if (configuration && configuration.authorization) {
				const localVarAuthorizationValue = typeof configuration.authorization === 'function'
					? configuration.authorization('bearerAuth')
					: configuration.authorization;
				if (localVarAuthorizationValue !== null) {
					localVarHeaderParameter.set("Authorization", "Bearer " + localVarAuthorizationValue);
				}
			}
			localVarRequestOptions.headers = localVarHeaderParameter;

			const localVarQueryParameterString = localVarQueryParameter.toString();
			if (localVarQueryParameterString) {
				localVarPath += "?" + localVarQueryParameterString;
			}
			return {
				url: localVarPath,
				options: localVarRequestOptions,
			};
		},
		/**
		 * @param {number} unitCategoryId
		 * @param {Api.UpdateUnitCategory} request
		 * @param {RequestInit} [options] Override http request option.
		 * @throws {RequiredError}
		 */
		updateUnitCategory(unitCategoryId: number, request: Api.UpdateUnitCategory, options: RequestInit = {}): FetchArgs {
			// verify required parameter 'unitCategoryId' is not null or undefined
			if (unitCategoryId === null || unitCategoryId === undefined) {
				throw new RequiredError('unitCategoryId', 'Required parameter unitCategoryId was null or undefined when calling updateUnitCategory.');
			}
			// verify required parameter 'request' is not null or undefined
			if (request === null || request === undefined) {
				throw new RequiredError('request', 'Required parameter request was null or undefined when calling updateUnitCategory.');
			}
			let localVarPath = `/unitCategory/{unitCategoryId}`
				.replace('{unitCategoryId}', encodeURIComponent(String(unitCategoryId)));
			const localVarPathQueryStart = localVarPath.indexOf("?");
			const localVarRequestOptions: RequestInit = Object.assign({ method: 'PUT' }, options);
			const localVarHeaderParameter: Headers = options.headers ? new Headers(options.headers) : new Headers();
			const localVarQueryParameter = new URLSearchParams(localVarPathQueryStart !== -1 ? localVarPath.substring(localVarPathQueryStart + 1) : "");
			if (localVarPathQueryStart !== -1) {
				localVarPath = localVarPath.substring(0, localVarPathQueryStart);
			}

			// authentication bearerAuth required
			// http authorization required
			if (configuration && configuration.authorization) {
				const localVarAuthorizationValue = typeof configuration.authorization === 'function'
					? configuration.authorization('bearerAuth')
					: configuration.authorization;
				if (localVarAuthorizationValue !== null) {
					localVarHeaderParameter.set("Authorization", "Bearer " + localVarAuthorizationValue);
				}
			}
			localVarHeaderParameter.set('Content-Type', 'application/json');

			localVarRequestOptions.headers = localVarHeaderParameter;
	
			if (request !== undefined) {
				localVarRequestOptions.body = JSON.stringify(request || {});
			}

			const localVarQueryParameterString = localVarQueryParameter.toString();
			if (localVarQueryParameterString) {
				localVarPath += "?" + localVarQueryParameterString;
			}
			return {
				url: localVarPath,
				options: localVarRequestOptions,
			};
		},
	}
};

/**
 * UnitCategoryApi - functional programming interface
 * @export
 */
export const UnitCategoryApiFp = function(configuration?: Configuration) {
	return {
		/**
		 * @param {Api.CreateUnitCategory} request
		 * @param {RequestInit} [options] Override http request option.
		 * @throws {RequiredError}
		 */
		createUnitCategory(request: Api.CreateUnitCategory, options?: RequestInit): (fetch?: FetchAPI, basePath?: string) => Promise<Api.UnitCategory> {
			const localVarFetchArgs = UnitCategoryApiFetchParamCreator(configuration).createUnitCategory(request, options);
			return (fetch: FetchAPI = defaultFetch, basePath: string = BASE_PATH) => {
				return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
					const contentType = response.headers.get('Content-Type');
					const mimeType = contentType ? contentType.replace(/;.*/, '') : undefined;
					
					if (response.status === 201) {
						if (mimeType === 'application/json') {
							return response.json() as any;
						}
						throw response;
					}
					if (response.status === 401) {
						if (mimeType === 'application/json') {
							throw response;
						}
						throw response;
					}
					if (response.status === 409) {
						if (mimeType === 'application/json') {
							throw response;
						}
						throw response;
					}
					if (response.status === 422) {
						if (mimeType === 'application/json') {
							throw response;
						}
						throw response;
					}
					if (response.status === 500) {
						if (mimeType === 'application/json') {
							throw response;
						}
						throw response;
					}
					throw response;
				});
			};
		},
		/**
		 * @param {number} unitCategoryId
		 * @param {RequestInit} [options] Override http request option.
		 * @throws {RequiredError}
		 */
		deleteUnitCategory(unitCategoryId: number, options?: RequestInit): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
			const localVarFetchArgs = UnitCategoryApiFetchParamCreator(configuration).deleteUnitCategory(unitCategoryId, options);
			return (fetch: FetchAPI = defaultFetch, basePath: string = BASE_PATH) => {
				return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
					const contentType = response.headers.get('Content-Type');
					const mimeType = contentType ? contentType.replace(/;.*/, '') : undefined;
					
					if (response.status === 204) {
						return response;
					}
					if (response.status === 401) {
						if (mimeType === 'application/json') {
							throw response;
						}
						throw response;
					}
					if (response.status === 404) {
						if (mimeType === 'application/json') {
							throw response;
						}
						throw response;
					}
					if (response.status === 422) {
						if (mimeType === 'application/json') {
							throw response;
						}
						throw response;
					}
					if (response.status === 500) {
						if (mimeType === 'application/json') {
							throw response;
						}
						throw response;
					}
					throw response;
				});
			};
		},
		/**
		 * @param {RequestInit} [options] Override http request option.
		 * @throws {RequiredError}
		 */
		getUnitCategories(options?: RequestInit): (fetch?: FetchAPI, basePath?: string) => Promise<Api.SimpleUnitCategory[]> {
			const localVarFetchArgs = UnitCategoryApiFetchParamCreator(configuration).getUnitCategories(options);
			return (fetch: FetchAPI = defaultFetch, basePath: string = BASE_PATH) => {
				return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
					const contentType = response.headers.get('Content-Type');
					const mimeType = contentType ? contentType.replace(/;.*/, '') : undefined;
					
					if (response.status === 200) {
						if (mimeType === 'application/json') {
							return response.json() as any;
						}
						throw response;
					}
					if (response.status === 401) {
						if (mimeType === 'application/json') {
							throw response;
						}
						throw response;
					}
					if (response.status === 500) {
						if (mimeType === 'application/json') {
							throw response;
						}
						throw response;
					}
					throw response;
				});
			};
		},
		/**
		 * @param {number} unitCategoryId
		 * @param {RequestInit} [options] Override http request option.
		 * @throws {RequiredError}
		 */
		getUnitCategory(unitCategoryId: number, options?: RequestInit): (fetch?: FetchAPI, basePath?: string) => Promise<Api.UnitCategory> {
			const localVarFetchArgs = UnitCategoryApiFetchParamCreator(configuration).getUnitCategory(unitCategoryId, options);
			return (fetch: FetchAPI = defaultFetch, basePath: string = BASE_PATH) => {
				return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
					const contentType = response.headers.get('Content-Type');
					const mimeType = contentType ? contentType.replace(/;.*/, '') : undefined;
					
					if (response.status === 200) {
						if (mimeType === 'application/json') {
							return response.json() as any;
						}
						throw response;
					}
					if (response.status === 401) {
						if (mimeType === 'application/json') {
							throw response;
						}
						throw response;
					}
					if (response.status === 404) {
						if (mimeType === 'application/json') {
							throw response;
						}
						throw response;
					}
					if (response.status === 422) {
						if (mimeType === 'application/json') {
							throw response;
						}
						throw response;
					}
					if (response.status === 500) {
						if (mimeType === 'application/json') {
							throw response;
						}
						throw response;
					}
					throw response;
				});
			};
		},
		/**
		 * @param {number} unitCategoryId
		 * @param {Api.UpdateUnitCategory} request
		 * @param {RequestInit} [options] Override http request option.
		 * @throws {RequiredError}
		 */
		updateUnitCategory(unitCategoryId: number, request: Api.UpdateUnitCategory, options?: RequestInit): (fetch?: FetchAPI, basePath?: string) => Promise<Api.UnitCategory> {
			const localVarFetchArgs = UnitCategoryApiFetchParamCreator(configuration).updateUnitCategory(unitCategoryId, request, options);
			return (fetch: FetchAPI = defaultFetch, basePath: string = BASE_PATH) => {
				return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
					const contentType = response.headers.get('Content-Type');
					const mimeType = contentType ? contentType.replace(/;.*/, '') : undefined;
					
					if (response.status === 200) {
						if (mimeType === 'application/json') {
							return response.json() as any;
						}
						throw response;
					}
					if (response.status === 401) {
						if (mimeType === 'application/json') {
							throw response;
						}
						throw response;
					}
					if (response.status === 404) {
						if (mimeType === 'application/json') {
							throw response;
						}
						throw response;
					}
					if (response.status === 409) {
						if (mimeType === 'application/json') {
							throw response;
						}
						throw response;
					}
					if (response.status === 422) {
						if (mimeType === 'application/json') {
							throw response;
						}
						throw response;
					}
					if (response.status === 500) {
						if (mimeType === 'application/json') {
							throw response;
						}
						throw response;
					}
					throw response;
				});
			};
		},
	}
};

/**
 * UnitCategoryApi - factory interface
 * @export
 */
export const UnitCategoryApiFactory: FactoryFunction<UnitCategoryApi> = function (configuration?: Configuration, basePath?: string, fetch?: FetchAPI) {
	return new UnitCategoryApi(configuration, basePath, fetch);
};

/**
 * UnitCategoryApi - object-oriented interface
 * @export
 * @class UnitCategoryApi
 * @extends {BaseAPI}
 */
export class UnitCategoryApi extends BaseAPI {
	/**
	 * @param {Api.CreateUnitCategory} request
	 * @param {RequestInit} [options] Override http request option.
	 * @throws {RequiredError}
	 */
	public createUnitCategory(request: Api.CreateUnitCategory, options?: RequestInit) {
		return UnitCategoryApiFp(this.configuration).createUnitCategory(request, options)(this.fetch, this.basePath);
	}

	/**
	 * @param {number} unitCategoryId
	 * @param {RequestInit} [options] Override http request option.
	 * @throws {RequiredError}
	 */
	public deleteUnitCategory(unitCategoryId: number, options?: RequestInit) {
		return UnitCategoryApiFp(this.configuration).deleteUnitCategory(unitCategoryId, options)(this.fetch, this.basePath);
	}

	/**
	 * @param {RequestInit} [options] Override http request option.
	 * @throws {RequiredError}
	 */
	public getUnitCategories(options?: RequestInit) {
		return UnitCategoryApiFp(this.configuration).getUnitCategories(options)(this.fetch, this.basePath);
	}

	/**
	 * @param {number} unitCategoryId
	 * @param {RequestInit} [options] Override http request option.
	 * @throws {RequiredError}
	 */
	public getUnitCategory(unitCategoryId: number, options?: RequestInit) {
		return UnitCategoryApiFp(this.configuration).getUnitCategory(unitCategoryId, options)(this.fetch, this.basePath);
	}

	/**
	 * @param {number} unitCategoryId
	 * @param {Api.UpdateUnitCategory} request
	 * @param {RequestInit} [options] Override http request option.
	 * @throws {RequiredError}
	 */
	public updateUnitCategory(unitCategoryId: number, request: Api.UpdateUnitCategory, options?: RequestInit) {
		return UnitCategoryApiFp(this.configuration).updateUnitCategory(unitCategoryId, request, options)(this.fetch, this.basePath);
	}

}
/**
 * UserApi - fetch parameter creator
 * @export
 */
export const UserApiFetchParamCreator = function (configuration?: Configuration) {
	return {
		/**
		 * @param {Api.CreateUser} request
		 * @param {RequestInit} [options] Override http request option.
		 * @throws {RequiredError}
		 */
		createUser(request: Api.CreateUser, options: RequestInit = {}): FetchArgs {
			// verify required parameter 'request' is not null or undefined
			if (request === null || request === undefined) {
				throw new RequiredError('request', 'Required parameter request was null or undefined when calling createUser.');
			}
			let localVarPath = `/user`;
			const localVarPathQueryStart = localVarPath.indexOf("?");
			const localVarRequestOptions: RequestInit = Object.assign({ method: 'POST' }, options);
			const localVarHeaderParameter: Headers = options.headers ? new Headers(options.headers) : new Headers();
			const localVarQueryParameter = new URLSearchParams(localVarPathQueryStart !== -1 ? localVarPath.substring(localVarPathQueryStart + 1) : "");
			if (localVarPathQueryStart !== -1) {
				localVarPath = localVarPath.substring(0, localVarPathQueryStart);
			}

			// authentication bearerAuth required
			// http authorization required
			if (configuration && configuration.authorization) {
				const localVarAuthorizationValue = typeof configuration.authorization === 'function'
					? configuration.authorization('bearerAuth')
					: configuration.authorization;
				if (localVarAuthorizationValue !== null) {
					localVarHeaderParameter.set("Authorization", "Bearer " + localVarAuthorizationValue);
				}
			}
			localVarHeaderParameter.set('Content-Type', 'application/json');

			localVarRequestOptions.headers = localVarHeaderParameter;
	
			if (request !== undefined) {
				localVarRequestOptions.body = JSON.stringify(request || {});
			}

			const localVarQueryParameterString = localVarQueryParameter.toString();
			if (localVarQueryParameterString) {
				localVarPath += "?" + localVarQueryParameterString;
			}
			return {
				url: localVarPath,
				options: localVarRequestOptions,
			};
		},
		/**
		 * @param {number} userId
		 * @param {RequestInit} [options] Override http request option.
		 * @throws {RequiredError}
		 */
		deleteUser(userId: number, options: RequestInit = {}): FetchArgs {
			// verify required parameter 'userId' is not null or undefined
			if (userId === null || userId === undefined) {
				throw new RequiredError('userId', 'Required parameter userId was null or undefined when calling deleteUser.');
			}
			let localVarPath = `/user/{userId}`
				.replace('{userId}', encodeURIComponent(String(userId)));
			const localVarPathQueryStart = localVarPath.indexOf("?");
			const localVarRequestOptions: RequestInit = Object.assign({ method: 'DELETE' }, options);
			const localVarHeaderParameter: Headers = options.headers ? new Headers(options.headers) : new Headers();
			const localVarQueryParameter = new URLSearchParams(localVarPathQueryStart !== -1 ? localVarPath.substring(localVarPathQueryStart + 1) : "");
			if (localVarPathQueryStart !== -1) {
				localVarPath = localVarPath.substring(0, localVarPathQueryStart);
			}

			// authentication bearerAuth required
			// http authorization required
			if (configuration && configuration.authorization) {
				const localVarAuthorizationValue = typeof configuration.authorization === 'function'
					? configuration.authorization('bearerAuth')
					: configuration.authorization;
				if (localVarAuthorizationValue !== null) {
					localVarHeaderParameter.set("Authorization", "Bearer " + localVarAuthorizationValue);
				}
			}
			localVarRequestOptions.headers = localVarHeaderParameter;

			const localVarQueryParameterString = localVarQueryParameter.toString();
			if (localVarQueryParameterString) {
				localVarPath += "?" + localVarQueryParameterString;
			}
			return {
				url: localVarPath,
				options: localVarRequestOptions,
			};
		},
		/**
		 * @param {RequestInit} [options] Override http request option.
		 * @throws {RequiredError}
		 */
		getProfile(options: RequestInit = {}): FetchArgs {
			let localVarPath = `/user/profile`;
			const localVarPathQueryStart = localVarPath.indexOf("?");
			const localVarRequestOptions: RequestInit = Object.assign({ method: 'GET' }, options);
			const localVarHeaderParameter: Headers = options.headers ? new Headers(options.headers) : new Headers();
			const localVarQueryParameter = new URLSearchParams(localVarPathQueryStart !== -1 ? localVarPath.substring(localVarPathQueryStart + 1) : "");
			if (localVarPathQueryStart !== -1) {
				localVarPath = localVarPath.substring(0, localVarPathQueryStart);
			}

			// authentication bearerAuth required
			// http authorization required
			if (configuration && configuration.authorization) {
				const localVarAuthorizationValue = typeof configuration.authorization === 'function'
					? configuration.authorization('bearerAuth')
					: configuration.authorization;
				if (localVarAuthorizationValue !== null) {
					localVarHeaderParameter.set("Authorization", "Bearer " + localVarAuthorizationValue);
				}
			}
			localVarRequestOptions.headers = localVarHeaderParameter;

			const localVarQueryParameterString = localVarQueryParameter.toString();
			if (localVarQueryParameterString) {
				localVarPath += "?" + localVarQueryParameterString;
			}
			return {
				url: localVarPath,
				options: localVarRequestOptions,
			};
		},
		/**
		 * @param {number} userId
		 * @param {RequestInit} [options] Override http request option.
		 * @throws {RequiredError}
		 */
		getUser(userId: number, options: RequestInit = {}): FetchArgs {
			// verify required parameter 'userId' is not null or undefined
			if (userId === null || userId === undefined) {
				throw new RequiredError('userId', 'Required parameter userId was null or undefined when calling getUser.');
			}
			let localVarPath = `/user/{userId}`
				.replace('{userId}', encodeURIComponent(String(userId)));
			const localVarPathQueryStart = localVarPath.indexOf("?");
			const localVarRequestOptions: RequestInit = Object.assign({ method: 'GET' }, options);
			const localVarHeaderParameter: Headers = options.headers ? new Headers(options.headers) : new Headers();
			const localVarQueryParameter = new URLSearchParams(localVarPathQueryStart !== -1 ? localVarPath.substring(localVarPathQueryStart + 1) : "");
			if (localVarPathQueryStart !== -1) {
				localVarPath = localVarPath.substring(0, localVarPathQueryStart);
			}

			// authentication bearerAuth required
			// http authorization required
			if (configuration && configuration.authorization) {
				const localVarAuthorizationValue = typeof configuration.authorization === 'function'
					? configuration.authorization('bearerAuth')
					: configuration.authorization;
				if (localVarAuthorizationValue !== null) {
					localVarHeaderParameter.set("Authorization", "Bearer " + localVarAuthorizationValue);
				}
			}
			localVarRequestOptions.headers = localVarHeaderParameter;

			const localVarQueryParameterString = localVarQueryParameter.toString();
			if (localVarQueryParameterString) {
				localVarPath += "?" + localVarQueryParameterString;
			}
			return {
				url: localVarPath,
				options: localVarRequestOptions,
			};
		},
		/**
		 * @param {RequestInit} [options] Override http request option.
		 * @throws {RequiredError}
		 */
		getUsers(options: RequestInit = {}): FetchArgs {
			let localVarPath = `/user`;
			const localVarPathQueryStart = localVarPath.indexOf("?");
			const localVarRequestOptions: RequestInit = Object.assign({ method: 'GET' }, options);
			const localVarHeaderParameter: Headers = options.headers ? new Headers(options.headers) : new Headers();
			const localVarQueryParameter = new URLSearchParams(localVarPathQueryStart !== -1 ? localVarPath.substring(localVarPathQueryStart + 1) : "");
			if (localVarPathQueryStart !== -1) {
				localVarPath = localVarPath.substring(0, localVarPathQueryStart);
			}

			// authentication bearerAuth required
			// http authorization required
			if (configuration && configuration.authorization) {
				const localVarAuthorizationValue = typeof configuration.authorization === 'function'
					? configuration.authorization('bearerAuth')
					: configuration.authorization;
				if (localVarAuthorizationValue !== null) {
					localVarHeaderParameter.set("Authorization", "Bearer " + localVarAuthorizationValue);
				}
			}
			localVarRequestOptions.headers = localVarHeaderParameter;

			const localVarQueryParameterString = localVarQueryParameter.toString();
			if (localVarQueryParameterString) {
				localVarPath += "?" + localVarQueryParameterString;
			}
			return {
				url: localVarPath,
				options: localVarRequestOptions,
			};
		},
		/**
		 * @param {number} userId
		 * @param {RequestInit} [options] Override http request option.
		 * @throws {RequiredError}
		 */
		resendConfirmation(userId: number, options: RequestInit = {}): FetchArgs {
			// verify required parameter 'userId' is not null or undefined
			if (userId === null || userId === undefined) {
				throw new RequiredError('userId', 'Required parameter userId was null or undefined when calling resendConfirmation.');
			}
			let localVarPath = `/user/resendConfirmation/{userId}`
				.replace('{userId}', encodeURIComponent(String(userId)));
			const localVarPathQueryStart = localVarPath.indexOf("?");
			const localVarRequestOptions: RequestInit = Object.assign({ method: 'PATCH' }, options);
			const localVarHeaderParameter: Headers = options.headers ? new Headers(options.headers) : new Headers();
			const localVarQueryParameter = new URLSearchParams(localVarPathQueryStart !== -1 ? localVarPath.substring(localVarPathQueryStart + 1) : "");
			if (localVarPathQueryStart !== -1) {
				localVarPath = localVarPath.substring(0, localVarPathQueryStart);
			}

			// authentication bearerAuth required
			// http authorization required
			if (configuration && configuration.authorization) {
				const localVarAuthorizationValue = typeof configuration.authorization === 'function'
					? configuration.authorization('bearerAuth')
					: configuration.authorization;
				if (localVarAuthorizationValue !== null) {
					localVarHeaderParameter.set("Authorization", "Bearer " + localVarAuthorizationValue);
				}
			}
			localVarRequestOptions.headers = localVarHeaderParameter;

			const localVarQueryParameterString = localVarQueryParameter.toString();
			if (localVarQueryParameterString) {
				localVarPath += "?" + localVarQueryParameterString;
			}
			return {
				url: localVarPath,
				options: localVarRequestOptions,
			};
		},
		/**
		 * @param {Api.UpdateProfileRequest} request
		 * @param {RequestInit} [options] Override http request option.
		 * @throws {RequiredError}
		 */
		updateProfile(request: Api.UpdateProfileRequest, options: RequestInit = {}): FetchArgs {
			// verify required parameter 'request' is not null or undefined
			if (request === null || request === undefined) {
				throw new RequiredError('request', 'Required parameter request was null or undefined when calling updateProfile.');
			}
			let localVarPath = `/user/profile`;
			const localVarPathQueryStart = localVarPath.indexOf("?");
			const localVarRequestOptions: RequestInit = Object.assign({ method: 'PATCH' }, options);
			const localVarHeaderParameter: Headers = options.headers ? new Headers(options.headers) : new Headers();
			const localVarQueryParameter = new URLSearchParams(localVarPathQueryStart !== -1 ? localVarPath.substring(localVarPathQueryStart + 1) : "");
			if (localVarPathQueryStart !== -1) {
				localVarPath = localVarPath.substring(0, localVarPathQueryStart);
			}

			// authentication bearerAuth required
			// http authorization required
			if (configuration && configuration.authorization) {
				const localVarAuthorizationValue = typeof configuration.authorization === 'function'
					? configuration.authorization('bearerAuth')
					: configuration.authorization;
				if (localVarAuthorizationValue !== null) {
					localVarHeaderParameter.set("Authorization", "Bearer " + localVarAuthorizationValue);
				}
			}
			localVarHeaderParameter.set('Content-Type', 'application/json');

			localVarRequestOptions.headers = localVarHeaderParameter;
	
			if (request !== undefined) {
				localVarRequestOptions.body = JSON.stringify(request || {});
			}

			const localVarQueryParameterString = localVarQueryParameter.toString();
			if (localVarQueryParameterString) {
				localVarPath += "?" + localVarQueryParameterString;
			}
			return {
				url: localVarPath,
				options: localVarRequestOptions,
			};
		},
		/**
		 * @param {number} userId
		 * @param {Api.UpdateUser} request
		 * @param {RequestInit} [options] Override http request option.
		 * @throws {RequiredError}
		 */
		updateUser(userId: number, request: Api.UpdateUser, options: RequestInit = {}): FetchArgs {
			// verify required parameter 'userId' is not null or undefined
			if (userId === null || userId === undefined) {
				throw new RequiredError('userId', 'Required parameter userId was null or undefined when calling updateUser.');
			}
			// verify required parameter 'request' is not null or undefined
			if (request === null || request === undefined) {
				throw new RequiredError('request', 'Required parameter request was null or undefined when calling updateUser.');
			}
			let localVarPath = `/user/{userId}`
				.replace('{userId}', encodeURIComponent(String(userId)));
			const localVarPathQueryStart = localVarPath.indexOf("?");
			const localVarRequestOptions: RequestInit = Object.assign({ method: 'PUT' }, options);
			const localVarHeaderParameter: Headers = options.headers ? new Headers(options.headers) : new Headers();
			const localVarQueryParameter = new URLSearchParams(localVarPathQueryStart !== -1 ? localVarPath.substring(localVarPathQueryStart + 1) : "");
			if (localVarPathQueryStart !== -1) {
				localVarPath = localVarPath.substring(0, localVarPathQueryStart);
			}

			// authentication bearerAuth required
			// http authorization required
			if (configuration && configuration.authorization) {
				const localVarAuthorizationValue = typeof configuration.authorization === 'function'
					? configuration.authorization('bearerAuth')
					: configuration.authorization;
				if (localVarAuthorizationValue !== null) {
					localVarHeaderParameter.set("Authorization", "Bearer " + localVarAuthorizationValue);
				}
			}
			localVarHeaderParameter.set('Content-Type', 'application/json');

			localVarRequestOptions.headers = localVarHeaderParameter;
	
			if (request !== undefined) {
				localVarRequestOptions.body = JSON.stringify(request || {});
			}

			const localVarQueryParameterString = localVarQueryParameter.toString();
			if (localVarQueryParameterString) {
				localVarPath += "?" + localVarQueryParameterString;
			}
			return {
				url: localVarPath,
				options: localVarRequestOptions,
			};
		},
	}
};

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function(configuration?: Configuration) {
	return {
		/**
		 * @param {Api.CreateUser} request
		 * @param {RequestInit} [options] Override http request option.
		 * @throws {RequiredError}
		 */
		createUser(request: Api.CreateUser, options?: RequestInit): (fetch?: FetchAPI, basePath?: string) => Promise<Api.User> {
			const localVarFetchArgs = UserApiFetchParamCreator(configuration).createUser(request, options);
			return (fetch: FetchAPI = defaultFetch, basePath: string = BASE_PATH) => {
				return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
					const contentType = response.headers.get('Content-Type');
					const mimeType = contentType ? contentType.replace(/;.*/, '') : undefined;
					
					if (response.status === 201) {
						if (mimeType === 'application/json') {
							return response.json() as any;
						}
						throw response;
					}
					if (response.status === 401) {
						if (mimeType === 'application/json') {
							throw response;
						}
						throw response;
					}
					if (response.status === 409) {
						if (mimeType === 'application/json') {
							throw response;
						}
						throw response;
					}
					if (response.status === 422) {
						if (mimeType === 'application/json') {
							throw response;
						}
						throw response;
					}
					if (response.status === 500) {
						if (mimeType === 'application/json') {
							throw response;
						}
						throw response;
					}
					if (response.status === 503) {
						if (mimeType === 'application/json') {
							throw response;
						}
						throw response;
					}
					throw response;
				});
			};
		},
		/**
		 * @param {number} userId
		 * @param {RequestInit} [options] Override http request option.
		 * @throws {RequiredError}
		 */
		deleteUser(userId: number, options?: RequestInit): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
			const localVarFetchArgs = UserApiFetchParamCreator(configuration).deleteUser(userId, options);
			return (fetch: FetchAPI = defaultFetch, basePath: string = BASE_PATH) => {
				return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
					const contentType = response.headers.get('Content-Type');
					const mimeType = contentType ? contentType.replace(/;.*/, '') : undefined;
					
					if (response.status === 204) {
						return response;
					}
					if (response.status === 401) {
						if (mimeType === 'application/json') {
							throw response;
						}
						throw response;
					}
					if (response.status === 404) {
						if (mimeType === 'application/json') {
							throw response;
						}
						throw response;
					}
					if (response.status === 422) {
						if (mimeType === 'application/json') {
							throw response;
						}
						throw response;
					}
					if (response.status === 500) {
						if (mimeType === 'application/json') {
							throw response;
						}
						throw response;
					}
					throw response;
				});
			};
		},
		/**
		 * @param {RequestInit} [options] Override http request option.
		 * @throws {RequiredError}
		 */
		getProfile(options?: RequestInit): (fetch?: FetchAPI, basePath?: string) => Promise<Api.Profile> {
			const localVarFetchArgs = UserApiFetchParamCreator(configuration).getProfile(options);
			return (fetch: FetchAPI = defaultFetch, basePath: string = BASE_PATH) => {
				return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
					const contentType = response.headers.get('Content-Type');
					const mimeType = contentType ? contentType.replace(/;.*/, '') : undefined;
					
					if (response.status === 200) {
						if (mimeType === 'application/json') {
							return response.json() as any;
						}
						throw response;
					}
					if (response.status === 401) {
						if (mimeType === 'application/json') {
							throw response;
						}
						throw response;
					}
					if (response.status === 404) {
						if (mimeType === 'application/json') {
							throw response;
						}
						throw response;
					}
					if (response.status === 500) {
						if (mimeType === 'application/json') {
							throw response;
						}
						throw response;
					}
					throw response;
				});
			};
		},
		/**
		 * @param {number} userId
		 * @param {RequestInit} [options] Override http request option.
		 * @throws {RequiredError}
		 */
		getUser(userId: number, options?: RequestInit): (fetch?: FetchAPI, basePath?: string) => Promise<Api.User> {
			const localVarFetchArgs = UserApiFetchParamCreator(configuration).getUser(userId, options);
			return (fetch: FetchAPI = defaultFetch, basePath: string = BASE_PATH) => {
				return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
					const contentType = response.headers.get('Content-Type');
					const mimeType = contentType ? contentType.replace(/;.*/, '') : undefined;
					
					if (response.status === 200) {
						if (mimeType === 'application/json') {
							return response.json() as any;
						}
						throw response;
					}
					if (response.status === 401) {
						if (mimeType === 'application/json') {
							throw response;
						}
						throw response;
					}
					if (response.status === 404) {
						if (mimeType === 'application/json') {
							throw response;
						}
						throw response;
					}
					if (response.status === 422) {
						if (mimeType === 'application/json') {
							throw response;
						}
						throw response;
					}
					if (response.status === 500) {
						if (mimeType === 'application/json') {
							throw response;
						}
						throw response;
					}
					throw response;
				});
			};
		},
		/**
		 * @param {RequestInit} [options] Override http request option.
		 * @throws {RequiredError}
		 */
		getUsers(options?: RequestInit): (fetch?: FetchAPI, basePath?: string) => Promise<Api.SimpleUser[]> {
			const localVarFetchArgs = UserApiFetchParamCreator(configuration).getUsers(options);
			return (fetch: FetchAPI = defaultFetch, basePath: string = BASE_PATH) => {
				return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
					const contentType = response.headers.get('Content-Type');
					const mimeType = contentType ? contentType.replace(/;.*/, '') : undefined;
					
					if (response.status === 200) {
						if (mimeType === 'application/json') {
							return response.json() as any;
						}
						throw response;
					}
					if (response.status === 401) {
						if (mimeType === 'application/json') {
							throw response;
						}
						throw response;
					}
					if (response.status === 500) {
						if (mimeType === 'application/json') {
							throw response;
						}
						throw response;
					}
					throw response;
				});
			};
		},
		/**
		 * @param {number} userId
		 * @param {RequestInit} [options] Override http request option.
		 * @throws {RequiredError}
		 */
		resendConfirmation(userId: number, options?: RequestInit): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
			const localVarFetchArgs = UserApiFetchParamCreator(configuration).resendConfirmation(userId, options);
			return (fetch: FetchAPI = defaultFetch, basePath: string = BASE_PATH) => {
				return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
					const contentType = response.headers.get('Content-Type');
					const mimeType = contentType ? contentType.replace(/;.*/, '') : undefined;
					
					if (response.status === 204) {
						return response;
					}
					if (response.status === 401) {
						if (mimeType === 'application/json') {
							throw response;
						}
						throw response;
					}
					if (response.status === 404) {
						if (mimeType === 'application/json') {
							throw response;
						}
						throw response;
					}
					if (response.status === 409) {
						if (mimeType === 'application/json') {
							throw response;
						}
						throw response;
					}
					if (response.status === 422) {
						if (mimeType === 'application/json') {
							throw response;
						}
						throw response;
					}
					if (response.status === 500) {
						if (mimeType === 'application/json') {
							throw response;
						}
						throw response;
					}
					throw response;
				});
			};
		},
		/**
		 * @param {Api.UpdateProfileRequest} request
		 * @param {RequestInit} [options] Override http request option.
		 * @throws {RequiredError}
		 */
		updateProfile(request: Api.UpdateProfileRequest, options?: RequestInit): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
			const localVarFetchArgs = UserApiFetchParamCreator(configuration).updateProfile(request, options);
			return (fetch: FetchAPI = defaultFetch, basePath: string = BASE_PATH) => {
				return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
					const contentType = response.headers.get('Content-Type');
					const mimeType = contentType ? contentType.replace(/;.*/, '') : undefined;
					
					if (response.status === 204) {
						return response;
					}
					if (response.status === 401) {
						if (mimeType === 'application/json') {
							throw response;
						}
						throw response;
					}
					if (response.status === 422) {
						if (mimeType === 'application/json') {
							throw response;
						}
						throw response;
					}
					if (response.status === 500) {
						if (mimeType === 'application/json') {
							throw response;
						}
						throw response;
					}
					throw response;
				});
			};
		},
		/**
		 * @param {number} userId
		 * @param {Api.UpdateUser} request
		 * @param {RequestInit} [options] Override http request option.
		 * @throws {RequiredError}
		 */
		updateUser(userId: number, request: Api.UpdateUser, options?: RequestInit): (fetch?: FetchAPI, basePath?: string) => Promise<Api.User> {
			const localVarFetchArgs = UserApiFetchParamCreator(configuration).updateUser(userId, request, options);
			return (fetch: FetchAPI = defaultFetch, basePath: string = BASE_PATH) => {
				return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
					const contentType = response.headers.get('Content-Type');
					const mimeType = contentType ? contentType.replace(/;.*/, '') : undefined;
					
					if (response.status === 200) {
						if (mimeType === 'application/json') {
							return response.json() as any;
						}
						throw response;
					}
					if (response.status === 401) {
						if (mimeType === 'application/json') {
							throw response;
						}
						throw response;
					}
					if (response.status === 404) {
						if (mimeType === 'application/json') {
							throw response;
						}
						throw response;
					}
					if (response.status === 409) {
						if (mimeType === 'application/json') {
							throw response;
						}
						throw response;
					}
					if (response.status === 422) {
						if (mimeType === 'application/json') {
							throw response;
						}
						throw response;
					}
					if (response.status === 500) {
						if (mimeType === 'application/json') {
							throw response;
						}
						throw response;
					}
					if (response.status === 503) {
						if (mimeType === 'application/json') {
							throw response;
						}
						throw response;
					}
					throw response;
				});
			};
		},
	}
};

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory: FactoryFunction<UserApi> = function (configuration?: Configuration, basePath?: string, fetch?: FetchAPI) {
	return new UserApi(configuration, basePath, fetch);
};

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI {
	/**
	 * @param {Api.CreateUser} request
	 * @param {RequestInit} [options] Override http request option.
	 * @throws {RequiredError}
	 */
	public createUser(request: Api.CreateUser, options?: RequestInit) {
		return UserApiFp(this.configuration).createUser(request, options)(this.fetch, this.basePath);
	}

	/**
	 * @param {number} userId
	 * @param {RequestInit} [options] Override http request option.
	 * @throws {RequiredError}
	 */
	public deleteUser(userId: number, options?: RequestInit) {
		return UserApiFp(this.configuration).deleteUser(userId, options)(this.fetch, this.basePath);
	}

	/**
	 * @param {RequestInit} [options] Override http request option.
	 * @throws {RequiredError}
	 */
	public getProfile(options?: RequestInit) {
		return UserApiFp(this.configuration).getProfile(options)(this.fetch, this.basePath);
	}

	/**
	 * @param {number} userId
	 * @param {RequestInit} [options] Override http request option.
	 * @throws {RequiredError}
	 */
	public getUser(userId: number, options?: RequestInit) {
		return UserApiFp(this.configuration).getUser(userId, options)(this.fetch, this.basePath);
	}

	/**
	 * @param {RequestInit} [options] Override http request option.
	 * @throws {RequiredError}
	 */
	public getUsers(options?: RequestInit) {
		return UserApiFp(this.configuration).getUsers(options)(this.fetch, this.basePath);
	}

	/**
	 * @param {number} userId
	 * @param {RequestInit} [options] Override http request option.
	 * @throws {RequiredError}
	 */
	public resendConfirmation(userId: number, options?: RequestInit) {
		return UserApiFp(this.configuration).resendConfirmation(userId, options)(this.fetch, this.basePath);
	}

	/**
	 * @param {Api.UpdateProfileRequest} request
	 * @param {RequestInit} [options] Override http request option.
	 * @throws {RequiredError}
	 */
	public updateProfile(request: Api.UpdateProfileRequest, options?: RequestInit) {
		return UserApiFp(this.configuration).updateProfile(request, options)(this.fetch, this.basePath);
	}

	/**
	 * @param {number} userId
	 * @param {Api.UpdateUser} request
	 * @param {RequestInit} [options] Override http request option.
	 * @throws {RequiredError}
	 */
	public updateUser(userId: number, request: Api.UpdateUser, options?: RequestInit) {
		return UserApiFp(this.configuration).updateUser(userId, request, options)(this.fetch, this.basePath);
	}

}

/**
 * We sometimes represent dates as strings (in models) and as Dates (in parameters) so this
 * function converts them both to a string.
 */
function dateToString(value: Date | string | undefined): string | undefined {
	if (value instanceof Date) {
		return value.toISOString();
	} else if (typeof value === 'string') {
		return value;
	} else {
		return undefined;
	}
}
